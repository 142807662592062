import { Form, Input } from "antd";

export default function FormInput(props) {
  const onChange = (e) => {
    const lastName = e.target.value;
    const firstName = props.form.getFieldValue("first_name") || null;
    const middleName = props.form.getFieldValue("middle_name") || null;

    const fullName = [lastName, firstName, middleName]
      .filter((item) => item)
      .join(" ");

    props.form.setFieldsValue({ full_name: fullName });
  };

  return (
    <Form.Item
      {...props}
      name="last_name"
      className="w-full"
      label="Фамилия"
      rules={[
        {
          required: true,
          message: "Это поле обязательно к заполнению",
        },
        {
          pattern: /^\S.*\S$/,
          message: "Нельзя использовать пробелы в начале и в конце строки",
        },
      ]}
    >
      <Input
        onChange={onChange}
        controls={false}
        className="w-full"
        placeholder="Введите фамилию"
      />
    </Form.Item>
  );
}
