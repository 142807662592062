import { useState, useEffect } from "react";
import { Loading } from "@components";
import { message, Form } from "antd";
import DataForm from "./form";
import * as API from "@api";

export default function EditData() {
  const type = "page:contacts";
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    API.Settings.getOne({ type }).then((res) => {
      if (res.error || !res) {
        return () =>
          message.error(
            "Ошибка во время получения данных, попробуйте позже",
            2
          );
      }

      setData(res.data);
      setLoading(false);
    });
  }, []);

  if (!data) {
    return <Loading compact />;
  }

  const onSave = async (values) => {
    setLoading(true);

    const data = form.getFieldValue("data");

    API.Settings.update({
      type,
      data,
    })
      .then(async (res) => {
        if (res?.error || !res?.data) {
          throw new Error(
            res?.message || "Ошибка при сохранении настроек, попробуйте позже"
          );
        }

        return message.success("Настройки сохранены", 1, () =>
          window.location.reload()
        );
      })
      .catch((err) =>
        message.error(
          err.message || "Ошибка при сохранении настроек, попробуйте позже",
          2
        )
      )
      .finally(() => setLoading(false));
  };

  return (
    <Form
      form={form}
      onFinish={onSave}
      onFinishFailed={(err) =>
        err?.errorFields?.map((msg) => message.error(msg.errors[0], 2))
      }
      layout="vertical"
      className="w-full form-container"
      initialValues={data}
    >
      <DataForm form={form} data={data} loading={loading} onSave={onSave} />
    </Form>
  );
}
