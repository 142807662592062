import { Form, Input } from "antd";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name={["data", "banner_text"]}
      className="w-full"
      help="Баннер будет расположен над шапкой сайта, напр. во время важных событий."
      label="Текст баннера сверху"
    >
      <Input
        controls={false}
        minLength={3}
        className="w-full"
        placeholder="Введите текст баннера"
      />
    </Form.Item>
  );
}
