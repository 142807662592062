import { useState, useEffect } from "react";
import { HTMLEditor } from "@components";
import { Button, Empty, Input, Modal, Tag, Popconfirm } from "antd";
import {
  PlusOutlined,
  EditOutlined,
  UpOutlined,
  DownOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Image from "./image";

function BlockEditor({ block, onCancel, onChange }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(block);
  }, [block]);

  const onSave = () => {
    onChange(data);
  };

  const onImageChange = (image) => {
    setData({ ...data, data: { ...data?.data, image } });
  };

  if (!data) {
    return null;
  }

  return (
    <Modal
      open={!!block}
      onCancel={onCancel}
      onOk={onSave}
      okText="Готово"
      title="Редактирование блока"
      maskClosable={false}
      centered
    >
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Image data={data?.data?.image} onChange={onImageChange} />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-semibold mb-2 text-black">
            Заголовок
          </span>
          <Input
            placeholder="Введите заголовок (необязательно)"
            className="w-full mb-2"
            value={data?.data?.title}
            onChange={(e) =>
              setData({
                ...data,
                data: { ...data?.data, title: e.target.value },
              })
            }
          />
          <span className="block text-sm font-semibold mb-2 text-black">
            Подзаголовок
          </span>
          <Input
            placeholder="Введите подзаголовок (необязательно)"
            className="w-full mb-2"
            value={data?.data?.subtitle}
            onChange={(e) =>
              setData({
                ...data,
                data: { ...data?.data, subtitle: e.target.value },
              })
            }
          />
          <span className="block text-sm font-semibold mb-2 text-black">
            Текст
          </span>
          <HTMLEditor
            className="w-full"
            value={data?.data?.text}
            onChange={(text) =>
              setData({ ...data, data: { ...data?.data, text } })
            }
          />
        </div>
      </div>
    </Modal>
  );
}

export default function StepByStep(params) {
  const blocks = params?.form?.getFieldValue("content");
  const [data, setData] = useState(blocks?.blocks || []);
  const [block, setBlock] = useState(null);

  useEffect(() => {
    params?.form?.setFieldsValue({ blocks: data });
  }, [data]);

  const onBlockAdd = () => {
    const newBlock = {
      id: Date.now(),
      type: "step-by-step",
      data: {
        image: null,
        image_caption: null,
        title: null,
        subtitle: null,
        text: null,
      },
    };
    setData([...data, newBlock]);
    setBlock(newBlock);
  };

  const onBlockChange = (editedBlock) => {
    setData(
      data.map((block) => {
        if (block.id === editedBlock.id) {
          return editedBlock;
        }
        return block;
      })
    );
    setBlock(null);
  };

  const onModalClose = () => setBlock(null);

  const onBlockPositionChange = (idx, direction) => {
    // add limitation if first or last block
    if (idx === 0 && direction === -1) return;
    if (idx === data.length - 1 && direction === 1) return;

    const newBlocks = [...data];
    const block = newBlocks[idx];
    newBlocks.splice(idx, 1);
    newBlocks.splice(idx + direction, 0, block);
    setData(newBlocks);
  };

  const onBlockDelete = (idx) => {
    setData(data.filter((b, i) => i !== idx));
  };

  return (
    <div className="w-full">
      {data?.map((b, i) => (
        <div
          className="flex flex-wrap justify-between items-start w-full p-3 my-2 bg-neutral-50 rounded-md"
          key={b?.id}
        >
          <div className="w-1/2">
            <span className="block text-base font-semibold mb-1">
              Блок {i + 1}
            </span>
            {b?.data?.image && (
              <Tag color="green" className="mb-1">
                Есть фото
              </Tag>
            )}
            {b?.data?.title && (
              <span className="block mb-1 text-black font-semibold">
                {b?.data?.title}
              </span>
            )}
            <div
              className="text-sm text-neutral-500 b-0"
              dangerouslySetInnerHTML={{ __html: b?.data?.text }}
            />
          </div>
          <div className="flex items-center">
            <Button.Group>
              <Button icon={<EditOutlined />} onClick={() => setBlock(b)}>
                Открыть
              </Button>
              <Button
                onClick={() => onBlockPositionChange(i, -1)}
                icon={<UpOutlined />}
              />
              <Button
                onClick={() => onBlockPositionChange(i, 1)}
                icon={<DownOutlined />}
              />
              <Popconfirm title="Удалить?" onConfirm={() => onBlockDelete(i)}>
                <Button icon={<DeleteOutlined className="text-rose-500" />} />
              </Popconfirm>
            </Button.Group>
          </div>
        </div>
      ))}
      <Empty hidden={data?.length > 0} className="my-10" />
      <Button
        onClick={onBlockAdd}
        size="large"
        type="dashed"
        block
        icon={<PlusOutlined />}
      >
        Добавить блок
      </Button>
      <BlockEditor
        block={block}
        onChange={onBlockChange}
        onCancel={onModalClose}
      />
    </div>
  );
}
