import { Breadcrumb, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import style from "./breadcrumb.module.scss";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";

export default function Breadcrumbs({
  data = [],
  className,
  showBack = false,
}) {
  const navigate = useNavigate();
  const items = data.map((item, i) => {
    return (
      <Breadcrumb.Item key={i} href={item.href}>
        {item.title}
      </Breadcrumb.Item>
    );
  });

  return (
    <Fragment>
      {showBack && (
        <Button
          icon={<LeftOutlined className="text-xs" />}
          size="small"
          type="link"
          className="m-0 p-0 mb-2 h-fit"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      )}
      <Breadcrumb
        className={classNames(style.container, className)}
        separator="/"
      >
        <Breadcrumb.Item href="/">Главная</Breadcrumb.Item>
        {items}
      </Breadcrumb>
    </Fragment>
  );
}
