import Password from "./password";
import ConfirmPassword from "./confirm_password";
import { Divider } from "antd";

export default function Details(props) {
  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="col-span-12">
        <Password {...props} />
      </div>
      <div className="col-span-12">
        <ConfirmPassword {...props} />
      </div>
      <div className="col-span-12">
        <Divider className="my-2" />
      </div>
    </div>
  );
}
