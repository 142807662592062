import { Form, Select } from "antd";
import { Dict } from "@utils";

export default function FormInput(props) {
  const options = Object.keys(Dict.META_OBJECT_TYPES).map((key) => ({
    label: Dict.META_OBJECT_TYPES[key].label,
    value: key,
  }));

  return (
    <Form.Item
      {...props}
      name="type"
      className="w-full"
      label="Тип SEO-записи"
      rules={[
        {
          required: true,
          message: "Пожалуйста, выберите значение",
        },
      ]}
    >
      <Select
        disabled
        placeholder="Выберите значение"
        className="w-full"
        filterOption={(input, option) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={options}
      />
    </Form.Item>
  );
}
