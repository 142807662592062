import { createReactEditorJS } from "react-editor-js";
import { EDITOR_JS_TOOLS } from "./tools";
import { useState, useRef, useCallback, useEffect } from "react";
import { Button, Modal, Input } from "antd";
import i18n from "./i18n";

const ReactEditorJS = createReactEditorJS();

export default function Editor(params) {
  const handleInitialize = useCallback((instance) => {
    params.editorInstance.current = instance;
  }, []);

  return (
    <div>
      <ReactEditorJS
        onInitialize={handleInitialize}
        tools={EDITOR_JS_TOOLS}
        i18n={i18n.ruRU}
        placeholder="Начните писать или добавьте блок"
        defaultValue={params.form.getFieldValue("content") || null}
      />
    </div>
  );
}
