import Name from "./name";
import Slug from "./slug";
import Description from "./description";
import Published from "./published";

export default function Details(props) {
  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="col-span-12">
        <Name {...props} />
      </div>
      <div className="col-span-12">
        <Slug {...props} />
      </div>
      <div className="col-span-12">
        <Description {...props} />
      </div>
      <div className="col-span-12">
        <Published {...props} />
      </div>
    </div>
  );
}
