import { Modal, DatePicker, Switch, message } from "antd";
import { useState } from "react";
import { SendOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

export default function Planned({ form, open, onCancel }) {
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      open={open}
      okText="Опубликовать сейчас"
      okButtonProps={{
        loading,
        icon: <SendOutlined />,
        disabled: loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
      onOk={() => {
        setLoading(true);
        form.setFieldsValue({ next_status: "published" });
        form.submit();
        setLoading(false);
      }}
      centered
      width={500}
      destroyOnClose
      onCancel={() => {
        if (loading) return;
        onCancel();
      }}
      title="Опубликовать спецпроект сейчас?"
    >
      <span className="block w-full text-left font-normal text-base text-neutral-600">
        Убедитесь, что все данные заполнены и проверены. Шаблон бренд-страницы
        спецпроекта должен быть подготовлен на клиентской части (Front-end), а
        материалы (если они будут подкреплены) уже опубликованны или
        запланированы.
      </span>
    </Modal>
  );
}
