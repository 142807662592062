import { Select } from "antd";

export default function ArticleFilter({ onChange, ...props }) {
  return (
    <Select
      className="w-full"
      placeholder="Статус"
      allowClear
      showSearch
      filterOption={(input, option) =>
        option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(v) => onChange("status", v)}
      options={[
        {
          value: "draft",
          label: "Черновик",
        },
        {
          value: "in_review",
          label: "На рассмотрении",
        },
        {
          value: "planned",
          label: "Запланирован на публикацию",
        },
        {
          value: "published",
          label: "Опубликован",
        },
        {
          value: "unpublished",
          label: "Снят с публикации",
        },
      ]}
      {...props}
    />
  );
}
