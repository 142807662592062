import { Card, Divider } from "antd";
import Main from "./main";
import Logo from "./logo";
import Banner from "./banner";

export default function Photos(props) {
  return (
    <Card title="Изображения" bordered={false}>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <span className="block w-full text-sm font-semibold leadning-none mb-3">
            <i className="not-italic text-rose-600">*</i> Логотип
          </span>
          <div className="w-full max-w-sm mx-auto">
            <Logo {...props} />
          </div>
        </div>
        <div className="col-span-12">
          <Divider className="my-2" />
        </div>
        <div className="col-span-12">
          <span className="block w-full text-sm font-semibold leadning-none mb-3">
            <i className="not-italic text-rose-600">*</i> Баннер малый в
            слайдере
          </span>
          <div className="w-full max-w-sm mx-auto">
            <Banner {...props} />
          </div>
        </div>
        <div className="col-span-12">
          <Divider className="my-2" />
        </div>
        <div className="col-span-12">
          <span className="block w-full text-sm font-semibold leadning-none mb-3">
            <i className="not-italic text-rose-600">*</i> Баннер основной (на
            странице «Спецпроекты»)
          </span>
          <div className="w-full max-w-sm mx-auto">
            <Main {...props} />
          </div>
        </div>
      </div>
    </Card>
  );
}
