// eslint-disable-next-line require-jsdoc
import Uploader from "./uploader";
import buttonIcon from "./svg/button-icon.svg";

import { PictureOutlined } from "@ant-design/icons";

require("./index.css").toString();

// eslint-disable-next-line require-jsdoc
export default class SimpleCarousel {
  /**
   * @param {CarousellData} data - previously saved data
   * @param {CarouselConfig} config - user config for Tool
   * @param {object} api - Editor.js API
   */
  constructor({ data, config, api }) {
    this.api = api;
    this.data = data;
    this.IconClose =
      '<svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M43 15L15 43M15 15L43 43" stroke="black" stroke-width="5" stroke-linecap="square"/></svg>';
    this.IconLeft =
      '<svg class="icon " viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M351,9a15,15 0 01 19,0l29,29a15,15 0 01 0,19l-199,199l199,199a15,15 0 01 0,19l-29,29a15,15 0 01-19,0l-236-235a16,16 0 01 0-24z" /></svg>';
    this.IconRight =
      '<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M312,256l-199-199a15,15 0 01 0-19l29-29a15,15 0 01 19,0l236,235a16,16 0 01 0,24l-236,235a15,15 0 01-19,0l-29-29a15,15 0 01 0-19z" /></svg>';
    this.config = {
      endpoints: config.endpoints || "",
      additionalRequestData: config.additionalRequestData || {},
      additionalRequestHeaders: config.additionalRequestHeaders || {},
      field: config.field || "image",
      types: config.types || "image/*",
      captionPlaceholder: this.api.i18n.t("Caption"),
      buttonContent: config.buttonContent || "",
      uploader: config.uploader || undefined,
    };
    /**
     * Module for file uploading
     */
    this.uploader = new Uploader({
      config: this.config,
      onUpload: (response) => this.onUpload(response),
      onError: (error) => this.uploadingFailed(error),
    });
  }

  /**
   * CSS classes
   * @constructor
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      input: this.api.styles.input,
      button: this.api.styles.button,

      /**
       * Tool's classes
       */
      wrapper: "cdxcarousel-wrapper",
      addButton: "cdxcarousel-addImage",
      block: "cdxcarousel-block",
      item: "cdxcarousel-item",
      removeBtn: "cdxcarousel-removeBtn",
      leftBtn: "cdxcarousel-leftBtn",
      rightBtn: "cdxcarousel-rightBtn",
      inputUrl: "cdxcarousel-inputUrl",
      caption: "cdxcarousel-caption",
      list: "cdxcarousel-list",
      imagePreloader: "image-tool__image-preloader",
    };
  }

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * @return {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      title: "Галерея",
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path stroke-width="0" d="M17.8081 10.2579C17.8081 10.917 17.2507 11.4512 16.563 11.4512C15.8763 11.4512 15.3188 10.9161 15.3188 10.2579C15.3188 9.59875 15.8763 9.0654 16.563 9.0654C17.2498 9.0654 17.8081 9.59875 17.8081 10.2579Z" fill="black"/>
      <path stroke-width="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.3834 6.20421C16.438 6.08292 15.2304 6.08292 13.7062 6.08292H10.2938C8.76865 6.08292 7.56103 6.08292 6.61652 6.20421C5.64435 6.32997 4.85592 6.59397 4.23516 7.18886C3.61441 7.78465 3.33881 8.53919 3.2077 9.47211C3.08105 10.3765 3.08105 11.5342 3.08105 12.996V13.0852C3.08105 14.547 3.08105 15.7047 3.2077 16.6108C3.33881 17.5428 3.61441 18.2974 4.23516 18.8923C4.85592 19.4881 5.64435 19.7521 6.61652 19.8769C7.56192 20 8.76954 20 10.2938 20H13.7062C15.2313 20 16.4389 20 17.3834 19.8778C18.3556 19.7529 19.144 19.4889 19.7648 18.8932C20.3855 18.2983 20.6611 17.5437 20.7923 16.6117C20.9189 15.7055 20.9189 14.5479 20.9189 13.0861V12.9969C20.9189 11.5342 20.9189 10.3774 20.7923 9.47121C20.6611 8.53919 20.3855 7.78465 19.7648 7.18886C19.144 6.59397 18.3556 6.32997 17.3834 6.20421ZM6.78241 7.38686C5.9476 7.49389 5.46687 7.69635 5.11457 8.03259C4.76406 8.36973 4.55357 8.82994 4.44119 9.62997C4.352 10.2721 4.33149 11.0748 4.32703 12.1237L4.74622 11.7714C5.7496 10.9304 7.26046 10.9785 8.2023 11.8811L11.761 15.2926C11.9373 15.4589 12.1649 15.5604 12.4065 15.5805C12.6481 15.6006 12.8893 15.5381 13.0908 15.4032L13.3387 15.2364C13.9203 14.8475 14.6129 14.6588 15.3115 14.699C16.01 14.7393 16.6764 15.0062 17.2095 15.4594L19.3563 17.3127C19.4437 17.0773 19.5106 16.7954 19.5588 16.4521C19.6729 15.6351 19.6747 14.5577 19.6747 13.0415C19.6747 11.5252 19.6729 10.4478 19.5588 9.62997C19.4464 8.82994 19.2359 8.36973 18.8845 8.03348C18.534 7.69635 18.0524 7.49478 17.2175 7.38686C16.3649 7.27716 15.2411 7.27538 13.6589 7.27538H10.3411C8.75884 7.27538 7.63506 7.27716 6.78241 7.38686Z" fill="black"/>
      <path stroke-width="0" d="M16.5359 3.59811C15.7688 3.5 14.7931 3.5 13.5757 3.5H10.8197C9.60318 3.5 8.62656 3.5 7.85953 3.59811C7.06575 3.70068 6.40396 3.91741 5.87864 4.41865C5.58872 4.69694 5.36763 5.03895 5.23291 5.41757C5.68242 5.21243 6.1908 5.09114 6.76161 5.01622C7.72842 4.89135 8.96459 4.89135 10.5254 4.89135H14.0163C15.5771 4.89135 16.8123 4.89135 17.7801 5.01622C18.2777 5.08132 18.729 5.18211 19.1348 5.34265C18.999 4.99356 18.7883 4.67847 18.5176 4.41954C17.9923 3.91741 17.3305 3.70068 16.5359 3.599V3.59811Z" fill="black"/>
      </svg>
      `,
    };
  }

  /**
   * Renders Block content
   * @public
   *
   * @return {HTMLDivElement}
   */
  render() {
    /*
     * Structure
     * <wrapper>
     *  <list>
     *    <item/>
     *    ...
     *  </list>
     *  <addButton>
     * </wrapper>
     */
    // Создаем базу для начала
    this.wrapper = make("div", [this.CSS.wrapper]);
    this.list = make("div", [this.CSS.list]);
    this.addButton = this.createAddButton();

    this.list.appendChild(this.addButton);
    this.wrapper.appendChild(this.list);
    if (this.data.length > 0) {
      for (const load of this.data) {
        const loadItem = this.creteNewItem(load.url, load.caption);

        this.list.insertBefore(loadItem, this.addButton);
      }
    }
    return this.wrapper;
  }

  // eslint-disable-next-line require-jsdoc
  save(blockContent) {
    const list = blockContent.getElementsByClassName(this.CSS.item);
    const data = [];

    if (list.length > 0) {
      for (const item of list) {
        if (item.firstChild.value) {
          data.push({
            url: item.firstChild.value,
            caption: item.lastChild.value,
          });
        }
      }
    }
    return data;
  }

  /**
   * Create Image block
   * @public
   *
   * @param {string} url - url of saved or upload image
   * @param {string} caption - caption of image
   *
   * Structure
   * <item>
   *  <url/>
   *  <removeButton/>
   *  <img/>
   *  <caption>
   * </item>
   *
   * @return {HTMLDivElement}
   */
  creteNewItem(url, caption) {
    // Create item, remove button and field for image url
    const block = make("div", [this.CSS.block]);
    const item = make("div", [this.CSS.item]);
    const removeBtn = make("div", [this.CSS.removeBtn]);
    const leftBtn = make("div", [this.CSS.leftBtn]);
    const rightBtn = make("div", [this.CSS.rightBtn]);
    const imageUrl = make("input", [this.CSS.inputUrl]);
    const imagePreloader = make("div", [this.CSS.imagePreloader]);

    imageUrl.value = url;
    leftBtn.innerHTML = this.IconLeft;
    leftBtn.style = "padding: 8px;";
    leftBtn.addEventListener("click", () => {
      var index = Array.from(block.parentNode.children).indexOf(block);

      if (index != 0) {
        block.parentNode.insertBefore(
          block,
          block.parentNode.children[index - 1]
        );
      }
    });
    rightBtn.innerHTML = this.IconRight;
    rightBtn.style = "padding: 8px;";
    rightBtn.addEventListener("click", () => {
      var index = Array.from(block.parentNode.children).indexOf(block);

      if (index != block.parentNode.children.length - 2) {
        block.parentNode.insertBefore(
          block,
          block.parentNode.children[index + 2]
        );
      }
    });
    removeBtn.innerHTML = this.IconClose;
    removeBtn.addEventListener("click", () => {
      block.remove();
    });
    removeBtn.style.display = "none";

    item.appendChild(imageUrl);
    item.appendChild(removeBtn);
    item.appendChild(leftBtn);
    item.appendChild(rightBtn);
    block.appendChild(item);
    /*
     * If data already yet
     * We create Image view
     */
    if (url) {
      this._createImage(url, item, caption, removeBtn);
    } else {
      item.appendChild(imagePreloader);
    }
    return block;
  }

  /**
   * Create Image View
   * @public
   *
   * @param {string} url - url of saved or upload image
   * @param {HTMLDivElement} item - block of created image
   * @param {string} captionText - caption of image
   * @param {HTMLDivElement} removeBtn - button for remove image block
   *
   * @return {HTMLDivElement}
   */
  _createImage(url, item, captionText, removeBtn) {
    const image = document.createElement("img");
    const caption = make("input", [this.CSS.caption, this.CSS.input]);

    image.src = url;
    if (captionText) {
      caption.value = captionText;
    }
    caption.placeholder = this.config.captionPlaceholder;

    removeBtn.style.display = "flex";

    item.appendChild(image);
    item.appendChild(caption);
  }

  /**
   * File uploading callback
   * @private
   *
   * @param {Response} response
   */
  onUpload(response) {
    if (response.success && response.file) {
      this._createImage(
        response.file.url,
        this.list.childNodes[this.list.childNodes.length - 2].firstChild,
        "",
        this.list.childNodes[this.list.childNodes.length - 2].firstChild
          .childNodes[1]
      );
      this.list.childNodes[
        this.list.childNodes.length - 2
      ].firstChild.childNodes[2].style.backgroundImage = "";
      this.list.childNodes[
        this.list.childNodes.length - 2
      ].firstChild.firstChild.value = response.file.url;
      this.list.childNodes[
        this.list.childNodes.length - 2
      ].firstChild.classList.add("cdxcarousel-item--empty");
    } else {
      this.uploadingFailed("incorrect response: " + JSON.stringify(response));
    }
  }

  /**
   * Handle uploader errors
   * @private
   *
   * @param {string} errorText
   */
  uploadingFailed(errorText) {
    this.api.notifier.show({
      message: this.api.i18n.t("Can not upload an image, try another"),
      style: "error",
    });
  }

  /**
   * Shows uploading preloader
   * @param {string} src - preview source
   */
  showPreloader(src) {
    this.nodes.imagePreloader.style.backgroundImage = `url(${src})`;
  }

  // eslint-disable-next-line require-jsdoc
  onSelectFile() {
    // Создаем элемент
    this.uploader.uploadSelectedFile({
      onPreview: (src) => {
        const newItem = this.creteNewItem("", "");
        newItem.firstChild.lastChild.style.backgroundImage = `url(${src})`;
        this.list.insertBefore(newItem, this.addButton);
      },
    });
  }

  /**
   * Create add button
   * @private
   */
  createAddButton() {
    const addButton = make("div", [this.CSS.button, this.CSS.addButton]);
    const block = make("div", [this.CSS.block]);

    // addButton.innerHTML = `${PictureOutlined} Add Image`;

    // paste <PictureOutlined /> icon into addButton
    const addIcon = document.createElement("img");
    addIcon.src = buttonIcon;
    addIcon.classList.add("icon");
    addButton.appendChild(addIcon);

    const addText = document.createElement("span");
    addText.classList.add(
      "text-sm",
      "block",
      "w-full",
      "text-center",
      "text-neutral-400"
    );
    addText.innerHTML = "Загрузить фото (Макс. 5MB)";
    addButton.appendChild(addText);

    addButton.addEventListener("click", () => {
      this.onSelectFile();
    });
    block.appendChild(addButton);

    return block;
  }
}

/**
 * Helper for making Elements with attributes
 *
 * @param  {string} tagName           - new Element tag name
 * @param  {array|string} classNames  - list or name of CSS class
 * @param  {Object} attributes        - any attributes
 * @return {Element}
 */
export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};
