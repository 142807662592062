import { Card, Dropdown } from "antd";
import {
  ReadOutlined,
  FieldBinaryOutlined,
  PictureOutlined,
  FontSizeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Articles } from "@modules";
import { Breadcrumb } from "@components";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import qs from "qs";

export default function ListPage() {
  const handleMenuClick = () => {};
  const [searchParams, setSearchParams] = useSearchParams();

  const menuItems = [
    {
      label: <Link to="/articles/new">Новость (стандарт)</Link>,
      key: "news",
      icon: <ReadOutlined />,
    },
    {
      type: "divider",
    },
    {
      label: <Link to="/articles/new?type_id=3">Лонгрид</Link>,
      key: "longread",
      icon: <FontSizeOutlined />,
    },
    {
      label: <Link to="/articles/new?type_id=7">Step-by-Step</Link>,
      key: "step-by-step",
      icon: <FieldBinaryOutlined />,
    },
    {
      label: <Link to="/articles/new?type_id=2">Галерея</Link>,
      key: "gallery",
      icon: <PictureOutlined />,
      disabled: true,
    },
  ];

  const setFilter = (filter) => {
    // console.log("filter", filter);
    setSearchParams(
      qs.stringify(filter, {
        arrayFormat: "brackets",
        encode: false,
        skipNulls: true,
        addQueryPrefix: true,
      })
    );
  };

  return (
    <div className="container max-content">
      <Breadcrumb data={[{ title: "Материалы", href: "/articles" }]} />
      <div className="flex justify-between items-center flex-wrap gap-4 mb-7">
        <h1 className="text-3xl m-0">Материалы</h1>
        <Dropdown.Button
          type="primary"
          className="w-fit m-0"
          menu={{
            items: menuItems,
            onClick: handleMenuClick,
          }}
        >
          <Link to="/articles/new">
            <PlusOutlined /> Создать материал
          </Link>
        </Dropdown.Button>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Card bordered={false}>
            <Articles.List
              searchParams={qs.parse(searchParams.toString(), {
                ignoreQueryPrefix: true,
              })}
              setSearchParams={setFilter}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}
