import { Form, DatePicker } from "antd";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name="publsihed_at"
      className="w-full"
      label="Дата публикации"
    >
      <DatePicker
        showTime
        className="w-full"
        format="DD.MM.YYYY HH:mm"
        placeholder="Выберите дату публикации (необязательно)"
      />
    </Form.Item>
  );
}
