import TopBanner from "./topbanner";
import TopBannerUrl from "./topbannerurl";
import MourningMode from "./mourning";

export default function Details(props) {
  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="col-span-12">
        <TopBanner {...props} />
      </div>
      <div className="col-span-12">
        <TopBannerUrl {...props} />
      </div>
      <div className="col-span-12">
        <MourningMode {...props} />
      </div>
    </div>
  );
}
