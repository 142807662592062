import { useState } from "react";
import { Card, Form, Input, Button, Divider } from "antd";
import classNames from "classnames";
import {
  ArrowLeftOutlined,
  UserOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useAuth } from "@contexts";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function LoginForm({ className }) {
  const [resetting, setResetting] = useState(false);
  const { loading, login, resetPassword, currentUser, isVerified } = useAuth();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onLogin = async (data) => {
    data.token = await executeRecaptcha();
    await login(data);
  };

  const onReset = async (data) => {
    data.token = await executeRecaptcha();
    await resetPassword(data);
  };

  return (
    <Card
      hidden={currentUser && !isVerified}
      className={classNames("rounded-md", className)}
    >
      <h2 className="text-xl text-black mb-10">Добро пожаловать</h2>
      <Form
        tabIndex={1}
        className="login-form !mb-0"
        name="signin"
        size="large"
        initialValues={{ remember: true }}
        onFinish={resetting ? onReset : onLogin}
      >
        <Form.Item
          name="email"
          className="my-4"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите вашу почту",
            },
            {
              type: "email",
              validateTrigger: "onSubmit",
              message: "Почта введена неверно",
            },
          ]}
        >
          <Input
            autoCapitalize="false"
            disabled={loading}
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Эл.почта"
          />
        </Form.Item>
        <Form.Item
          hidden={resetting}
          name="password"
          className="my-4"
          rules={[
            {
              min: 6,
              required: !resetting,
              message: "Пожалуйста, введите пароль",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Пароль"
            disabled={loading}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="admin-login-form-button"
            loading={loading}
            block
          >
            {resetting ? "Отправить" : "Войти"}
          </Button>
        </Form.Item>
        <Form.Item className="!mb-0" style={{ fontSize: 15 }}>
          <div className="flex justify-between items-center w-full">
            <Button
              className="m-0 p-0"
              type="link"
              disabled={loading}
              hidden={resetting}
              onClick={() => setResetting(true)}
            >
              Забыли пароль?
            </Button>
            <Button
              className="m-0 p-0"
              type="link"
              hidden={!resetting}
              disabled={loading}
              onClick={() => setResetting(false)}
              icon={<ArrowLeftOutlined />}
            >
              Назад
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Card>
  );
}
