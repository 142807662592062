import axios from "./default";

const API = "/rates";

export const getAll = (params) =>
  axios
    .get(`${API}/`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));

export const getOil = (params) =>
  axios
    .get(`${API}/oil`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));

export const getCrypto = (params) =>
  axios
    .get(`${API}/crypto`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));

export const getNbk = (params) =>
  axios
    .get(`${API}/nbk`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));
