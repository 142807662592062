import { Form, Input } from "antd";
import dayjs from "dayjs";

export default function FormInput(props) {
  const createdAt = props?.form?.getFieldValue("created_at");

  return (
    <Form.Item
      {...props}
      name="slug"
      className="w-full"
      label="Slug (URL-адрес)"
      help="В целях поддержки SEO-оптимизации, Slug тега можно поменять только в течение 7 дней после создания тега"
    >
      <Input
        controls={false}
        minLength={3}
        className="w-full"
        placeholder="Введите URL-имя, например: halyk-bank"
        disabled={
          // Если тег создан более 7 дней назад, то запрещаем редактировать slug
          createdAt && dayjs().diff(dayjs(createdAt), "day") > 7
        }
      />
    </Form.Item>
  );
}
