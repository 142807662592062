import { Select } from "antd";
import { Dict } from "@utils";

export default function FilterItem({ onChange, ...props }) {
  const data = Object.keys(Dict.META_OBJECT_TYPES).map((key) => ({
    label: Dict.META_OBJECT_TYPES[key].label,
    value: key,
  }));

  return (
    <Select
      mode="multiple"
      className="w-full"
      placeholder="Тип SEO"
      allowClear
      showSearch
      filterOption={(input, option) =>
        option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toLowerCase())
      }
      onChange={(v) => onChange("type", v)}
      options={data}
      {...props}
    />
  );
}
