import { Card, message } from "antd";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getImage } from "@utils/imgproxy";
import { EyeOutlined } from "@ant-design/icons";
import { Loading } from "@components";
import Fade from "react-reveal/Fade";
import * as API from "@api";

export default function Popular() {
  const [data, setData] = useState([]);

  useEffect(() => {
    API.Articles.getAll({
      filter: {
        status: "published",
        // published_at: {
        //   from: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
        //   to: dayjs().format("YYYY-MM-DD"),
        // },
      },
      limit: 10,
      order: {
        views: "desc",
      },
    })
      .then((res) => setData(res?.data?.rows || []))
      .catch((err) =>
        message.error(err.message || "Ошибка при запросе данных", 2)
      );
  }, []);

  return (
    <Card
      className="h-full bg-neutral-900 transition-all hover:scale-150"
      bordered={false}
    >
      <div className="grid grid-cols-12 gap-6 p-1">
        <div className="col-span-12 lg:col-span-3">
          <span className="text-xl text-center lg:text-left lg:text-5xl block font-semibold text-white">
            <i className="not-italic text-primary">Популярное</i> за неделю
          </span>

          <div className="hidden lg:block relative w-full h-52 mt-10">
            <img
              className="block w-full h-44 object-cover object-center rounded-md shadow-xl shadow-neutral-800 absolute z-50"
              src={getImage(data[0]?.image || "img_placeholder.jpg")}
              alt="Популярные материалы"
            />
            <img
              className="block h-44 object-cover object-center rounded-md shadow-xl shadow-neutral-800 absolute z-40 mx-auto left-0 right-0 top-3"
              style={{ width: "95%" }}
              src={getImage(data[1]?.image || "img_placeholder.jpg")}
              alt="Популярные материалы"
            />
            <img
              className="block h-44 object-cover object-center rounded-md shadow-xl shadow-neutral-800 absolute z-30 mx-auto left-0 right-0 top-6"
              style={{ width: "90%" }}
              src={getImage(data[3]?.image || "img_placeholder.jpg")}
              alt="Популярные материалы"
            />
          </div>
        </div>
        <Fade bottom distance="30px" cascade duration={400}>
          <div className="col-span-12 lg:col-span-9 grid grid-cols-10 gap-4">
            {!data.length && (
              <div className="col-span-10 flex justify-center items-center">
                <Loading className="bg-neutral-800 rounded-md" />
              </div>
            )}
            {data?.map((article) => (
              <Link
                to={`/articles/edit/${article.id}`}
                className="col-span-10 sm:col-span-5 lg:col-span-2 flex flex-col gap-2 hover:opacity-75 transition-all"
              >
                <img
                  className="block w-full h-24 object-cover object-center rounded-sm"
                  src={getImage(article.image)}
                  alt={article.title}
                />
                <span className="text-sm text-neutral-100 font-medium leading-tight line-clamp-2">
                  {article.title}
                </span>
                <div className="flex justify-between items-center">
                  <div>
                    <EyeOutlined className="text-sm text-neutral-400" />{" "}
                    <span className="text-sm text-neutral-400">
                      {article.views}
                    </span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </Fade>
      </div>
    </Card>
  );
}
