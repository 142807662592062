import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export default function ArticleFilter({ onSearch, ...props }) {
  return (
    <Input.Search
      placeholder="Поиск по эл.почте"
      allowClear
      onSearch={(value) => onSearch("email", value)}
      enterButton="Найти"
      prefix={<SearchOutlined />}
      {...props}
    />
  );
}
