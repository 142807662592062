import { Card, Badge, Tooltip, Button } from "antd";
import { Link } from "react-router-dom";
import { Articles } from "@modules";
import { useState } from "react";
import Details from "./details";
import Photos from "./photo";
import Actions from "./actions";

export default function EditEntry({ data, form }) {
  const [articleFilters, setArticleFilters] = useState({
    filter: { project_id: data?.id },
  });

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 xl:col-span-8 flex flex-col gap-4 h-full">
        <Card
          title="Основная информация"
          bordered={false}
          extra={
            <Tooltip title="Текущий статус проекта">
              <Badge
                text={data?.published ? "Опубликован" : "Черновик"}
                status={data?.published ? "success" : "default"}
                className="m-0"
              />
            </Tooltip>
          }
        >
          <Details data={data} form={form} />
        </Card>
        <Card
          title="Подкрепленные материалы"
          bordered={false}
          className="h-full"
          extra={
            <Link to="/articles">
              <Button type="link" className="m-0 p-0 h-fit w-fit">
                Все материалы
              </Button>
            </Link>
          }
        >
          <Articles.List
            searchParams={articleFilters}
            setSearchParams={setArticleFilters}
            showFilters={[]}
            showHeader={false}
            compact
          />
        </Card>
      </div>
      <div className="col-span-12 xl:col-span-4 flex flex-col gap-4">
        <Actions data={data} form={form} />
        <Photos data={data} form={form} />
      </div>
    </div>
  );
}
