import { Card, Divider, Input, Empty, Button } from "antd";
import { FundFilled, PlusOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { Loading, HTMLEditor } from "@components";
import * as API from "@api";
import Image from "./image";

export default function SEO({ form }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    loadMeta();
  }, []);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        seo: data,
      });
    }
  }, [data]);

  const loadMeta = () =>
    API.Meta.getAll({
      filter: {
        type: "category",
        object_id: form.getFieldValue("id"),
      },
    })
      .then((res) => res && setData(res?.data?.rows[0] || null))
      .finally(() => setLoading(false));

  const onMetaCreate = async () => {
    await API.Meta.create({
      type: "category",
      object_id: form.getFieldValue("id"),
      url: `/${form.getFieldValue("slug")}`,
    }).then(() => loadMeta());
  };

  if (loading && !data) {
    return <Loading compact />;
  }

  if (!loading && !data) {
    return (
      <div className="flex flex-col justify-center items-center gap-4 my-16">
        <Empty description="Отдельной SEO-записи для этой рубрики пока нет" />
        <Button onClick={onMetaCreate} icon={<PlusOutlined />}>
          Создать запись
        </Button>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 my-3">
      <div>
        <span className="text-sm text-black mb-2 block">SEO-заголовок</span>
        <Input
          value={data?.title}
          onChange={(e) => setData({ ...data, title: e.target.value })}
          disabled={loading}
          placeholder="Введите SEO-заголовок"
        />
      </div>
      <div>
        <span className="text-sm text-black mb-2 block">SEO-описание</span>
        <Input.TextArea
          disabled={loading}
          rows={3}
          value={data?.description}
          onChange={(e) => setData({ ...data, description: e.target.value })}
          placeholder="Введите SEO-описание"
        />
      </div>
      <div>
        <span className="text-sm text-black mb-2 block">SEO-теги</span>
        <Input
          disabled={loading}
          value={data?.keywords}
          onChange={(e) => setData({ ...data, keywords: e.target.value })}
          placeholder="Введите SEO-теги (через запятую)"
        />
      </div>
      <Divider className="my-2" />
      <div>
        <span className="text-sm text-black mb-2 block">
          HTML-описание в конце страницы
        </span>
        <HTMLEditor
          className="w-full mt-2"
          value={data?.footer_text}
          onChange={(footer_text) => setData({ ...data, footer_text })}
        />
      </div>
      <Divider className="my-2" />
      <Image
        image={data?.image}
        onChange={(image) => setData({ ...data, image })}
      />
      <Divider className="my-2" />
    </div>
  );
}
