import { useEffect, useState } from "react";
import { message, Select, Spin, Form } from "antd";
import debounce from "debounce";
import * as API from "@api";

export default function FormInput(params) {
  const [loading, setLoading] = useState(true);
  const [initialData, setInitialData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const data = params?.form?.getFieldValue("people") || [];

    if (data.length) {
      setSelected(data);

      API.People.getAll({
        limit: data?.length || 10,
        filter: { id: data?.map((item) => item?.id) },
      })
        .then((res) => setInitialData(res?.data?.rows || []))
        .finally(() => setLoading(false));

      return;
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    params.form.setFieldsValue({ people: selected });
  }, [selected]);

  useEffect(() => {
    if (!initialData?.length) {
      return;
    }

    setOptions(
      initialData?.map((item) => ({
        value: item.id,
        label: `${item.full_name} (${
          item.type === "expert" ? "Эксперт" : "Досье"
        })`,
        data: item,
      }))
    );
  }, [initialData]);

  let fetchData = async (value) => {
    setOptions([]);
    setFetching(true);
    const data = await API.People.getAll({
      limit: 10,
      q: value,
    });

    if (!data || data?.error) {
      return message.error(
        "Невозможно произвести поиск, проверьте интернет-соединение",
        3
      );
    }

    setOptions(
      data?.data?.rows?.map((item) => ({
        value: item.id,
        label: `${item.full_name} (${
          item.type === "expert" ? "Эксперт" : "Досье"
        })`,
        data: item,
      }))
    );

    setFetching(false);
  };

  fetchData = debounce(fetchData, 200);

  if (loading) return null;

  return (
    <Form.Item {...params} className="w-full" label="Подкрепленные Личности">
      <Select
        mode="multiple"
        onChange={(_, values) => {
          setSelected(values?.map((val) => val?.data));
        }}
        defaultValue={initialData?.map((item) => item.id) || []}
        showSearch
        className="w-full"
        allowClear
        placeholder="Поиск по личностям"
        filterOption={false}
        onSearch={fetchData}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        options={options}
      />
    </Form.Item>
  );
}
