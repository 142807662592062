import { DatePicker } from "antd";
import dayjs from "dayjs";

export default function FilterItem({ onChange, ...props }) {
  return (
    <DatePicker.RangePicker
      placeholder={["Создан с …", "по …"]}
      onChange={(d) => {
        if (!d) {
          return onChange("created_at", null);
        }

        onChange("created_at", {
          from: d[0].format("YYYY-MM-DD"),
          to: d[1].format("YYYY-MM-DD"),
        });
      }}
      format="DD.MM.YYYY"
      className="w-full"
      defaultValue={
        props.defaultValue && [
          dayjs(props.defaultValue.from),
          dayjs(props.defaultValue.to),
        ]
      }
    />
  );
}
