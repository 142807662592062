import { Form, Switch } from "antd";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name={["data", "mourning_mode"]}
      className="w-full"
      help="Сайт будет отображаться в ч/б режиме. Заполните поля баннера выше, чтобы отобразить его."
      label="Режим траура (ч/б)"
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
  );
}
