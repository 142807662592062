import { Form, Input } from "antd";
import { MailOutlined } from "@ant-design/icons";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name="email"
      className="w-full"
      label="Эл.почта"
      rules={[
        {
          required: true,
          type: "email",
          message: "Пожалуйста, введите корректный адрес эл.почты",
        },
      ]}
    >
      <Input
        addonBefore={<MailOutlined />}
        controls={false}
        minLength={3}
        className="w-full"
        placeholder="Введите имя пользователя"
      />
    </Form.Item>
  );
}
