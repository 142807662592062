import Main from "./main";
import Preview from "./preview";
import Caption from "./caption";

export default function Photos(props) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 lg:col-span-6">
        <span className="block w-full text-sm font-semibold leadning-none mb-3">
          <i className="not-italic text-rose-600">*</i> Основное фото
        </span>
        <Main {...props} />
      </div>
      <div className="col-span-12 lg:col-span-6">
        <span className="block w-full text-sm font-semibold leadning-none mb-3">
          Фото карточки (превью в списке, необязательно)
        </span>
        <Preview {...props} />
      </div>
      <div className="col-span-12">
        <Caption {...props} />
      </div>
    </div>
  );
}
