import { Form } from "antd";
import { HTMLEditor } from "@components";

export default function FormInput(props) {
  return (
    <Form.Item label="Биография персоны">
      <HTMLEditor
        className="w-full mt-2"
        value={props?.form?.getFieldValue("biography")}
        onChange={(value) => {
          props?.form?.setFieldsValue({
            biography: value,
          });
        }}
      />
    </Form.Item>
  );
}
