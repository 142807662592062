import { Form, Input } from "antd";
import { LockOutlined } from "@ant-design/icons";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name="password"
      className="w-full"
      label="Новый пароль"
    >
      <Input.Password
        addonBefore={<LockOutlined />}
        controls={false}
        minLength={3}
        className="w-full"
        placeholder="Введите новый пароль"
      />
    </Form.Item>
  );
}
