import { Select } from "antd";

export default function FilterItem({ onChange, ...props }) {
  return (
    <Select
      className="w-full"
      placeholder="Тип"
      allowClear
      showSearch
      filterOption={(input, option) =>
        option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(v) => onChange("type", v)}
      options={[
        {
          value: "expert",
          label: "Эксперт",
        },
        {
          value: "dossier",
          label: "Досье",
        },
        {
          value: "both",
          label: "Эксперт + Досье",
        },
      ]}
      {...props}
    />
  );
}
