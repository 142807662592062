import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export default function Filter({ onSearch, ...props }) {
  return (
    <Input.Search
      placeholder="Поиск по названию"
      allowClear
      onSearch={(value) => onSearch("name", value)}
      enterButton="Найти"
      prefix={<SearchOutlined />}
      {...props}
    />
  );
}
