import { useEffect, useState, Fragment } from "react";
import Name from "./name";
import Longname from "./long_name";
import Slug from "./slug";
import NameLocalized from "./name_localized";
import LongNameLocalized from "./long_name_localized";
import Parent from "./parent";
import * as API from "@api";

import { Divider, Tag } from "antd";

export default function Details(props) {
  const [locales, setLocales] = useState([]);

  useEffect(() => {
    API.Locales.getAll().then((res) =>
      setLocales(res?.data?.rows?.filter((locale) => locale?.code !== "ru"))
    );
  }, []);

  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="col-span-12">
        <Tag>ID {props?.form?.getFieldValue("id")}</Tag>
      </div>
      <div className="col-span-12">
        <Name {...props} />
      </div>
      <div className="col-span-12">
        <Longname {...props} />
      </div>
      <div className="col-span-12">
        <Slug {...props} />
      </div>
      <div className="col-span-12">
        <Divider className="my-2" />
      </div>
      {locales?.map((locale) => (
        <Fragment key={locale?.id}>
          <div className="col-span-12">
            <NameLocalized {...props} locale={locale} />
          </div>
          <div className="col-span-12">
            <LongNameLocalized {...props} locale={locale} />
          </div>
        </Fragment>
      ))}
      <div className="col-span-12">
        <Divider className="my-2" />
      </div>
      <div className="col-span-12">
        <Parent {...props} />
      </div>
      <div className="col-span-12">
        <Divider className="my-2" />
      </div>
    </div>
  );
}
