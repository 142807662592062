import axios from "./default";

const API = "/auth";

// Returns current user auth details
export const getAuth = () =>
  axios
    .get(`${API}/`, {
      withCredentials: true,
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));

// Sign In user
export const signin = (data, params) =>
  axios
    .post(`${API}/signin`, data, { params }, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));

// Logout user
export const logout = (data) =>
  axios
    .post(`${API}/logout`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));

// Request pass reset
export const resetPass = (data, params) =>
  axios
    .post(`${API}/password/reset`, data, { params }, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));
