import { Select, Form } from "antd";
import { useEffect, useState } from "react";
import * as API from "@api";

export default function FormInput(params) {
  const [data, setData] = useState([]);
  const id = params.form.getFieldValue("id");

  useEffect(() => {
    API.Categories.getAll({ limit: 1000 }).then((res) =>
      setData(
        res?.data?.rows.map((val) => ({
          label: val?.parent?.id
            ? `ID ${val.id}: ${val?.name} (является дочерней «${val?.parent?.name}»)`
            : `ID ${val.id}: ${val?.name}`,
          value: val?.id,
          disabled: val?.id === id,
        })) || []
      )
    );
  }, []);

  return (
    <Form.Item
      {...params}
      name="parent_id"
      className="w-full"
      label="Родительская рубрика"
    >
      <Select
        className="w-full"
        placeholder="Выберите рубрику"
        allowClear
        showSearch
        filterOption={(input, option) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={data}
        {...params}
      />
    </Form.Item>
  );
}
