import { List, message } from "antd";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Imgproxy } from "@utils";
import * as API from "@api";

export default function ArticleList(props) {
  const id = props?.form?.getFieldValue("object_id");
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getTableData();
  }, []);

  async function getTableData(params, _, sorter) {
    setLoading(true);

    const _res = await API.Articles.getAll({
      filter: {
        id,
      },
      limit: 1,
    });

    if (!_res.data) {
      return message.error(
        "Ошибка во время загрузки материалов, попробуйте позже",
        2
      );
    }

    setTableData(_res.data.rows);
    setLoading(false);
  }

  return (
    <List
      loading={loading}
      dataSource={tableData}
      onChange={getTableData}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <img
                loading="lazy"
                src={
                  item?.image
                    ? Imgproxy.getImage(item?.image, {
                        width: 224,
                        height: 150,
                      })
                    : "/img/no-img.svg"
                }
                alt="Изображение материала"
                className="block mx-auto w-28 object-contain rounded-sm bg-white"
              />
            }
            title={
              <div className="flex flex-col">
                <span className="text-sm block text-primary font-normal mb-1">
                  {item?.category?.name}
                </span>
                <Link
                  to={`/articles/edit/${item?.id}`}
                  className="text-base font-semibold block leading-snug text-black"
                >
                  {item?.title}
                </Link>
              </div>
            }
            description={item?.subtitle}
          />
        </List.Item>
      )}
    />
  );
}
