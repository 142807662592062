import { Form, Input, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useState } from "react";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name="full_name"
      className="w-full"
      label="ФИО"
      rules={[
        {
          pattern: /^\S.*\S$/,
          message: "Нельзя использовать пробелы в начале и в конце строки",
        },
      ]}
    >
      <Input
        controls={false}
        className="w-full"
        placeholder="Заполнится автоматически"
      />
    </Form.Item>
  );
}
