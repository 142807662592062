import { useEffect, useState } from "react";
import { Table, message, Button, Tag, Badge } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { includes } from "ramda";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import * as API from "@api";
import * as Filters from "./filters";

dayjs.extend(relativeTime);

export default function DataTable({
  showHeader = true,
  showFilters = ["search", "role", "email", "email_confirmed", "status"],
  searchParams = null,
  setSearchParams = () => {},
  pageSize = 10,
  showPagination = true,
  compact = false,
}) {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState(searchParams?.filter || {});
  const [pagination, setPagination] = useState({
    current: searchParams?.pagination?.current
      ? parseInt(searchParams.pagination.current)
      : 1,
    pageSize,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
    showTotal: (total) => `Найдено: ${total}`,
  });

  useEffect(() => {
    getTableData();
  }, [filter]);

  async function getTableData(params = pagination, _, sorter) {
    setLoading(true);

    setSearchParams({
      filter,
      pagination: {
        current: params.current,
      },
    });

    const _res = await API.Users.getAll({
      filter,
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
      order: sorter?.field && {
        [sorter?.field]: sorter?.order === "ascend" ? "ASC" : "DESC",
      },
    });

    if (!_res.data) {
      return message.error("Ошибка при запросе данных, попробуйте позже", 2);
    }

    setTableData(_res.data.rows);
    setPagination({
      ...params,
      total: _res.data.count,
      showTotal: (total) => `Найдено: ${total}`,
    });
    setLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter(
      { ...filter, [f]: v || undefined },
      setPagination({ ...pagination, current: 1 })
    );
  }

  const columns = [
    {
      dataIndex: "info",
      key: "info",
      align: "left",
      title: "Пользователь",
      width: 300,
      render: (_, data) => (
        <Link to={`/users/edit/${data.id}`}>
          <span className="block text-base font-medium text-black leading-tight mb-1">
            {data?.name}
          </span>
          <span className="block text-sm font-normal text-neutral-500 leading-tight">
            {data?.email}{" "}
            {!data?.email_confirmed && (
              <Tag className="m-0" color="red">
                Не подтвержден
              </Tag>
            )}
          </span>
        </Link>
      ),
    },
    {
      dataIndex: "role",
      key: "role",
      align: "center",
      title: "Роль",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (data) => (
        <div>
          {data === "admin" && <Tag color="blue">Админ</Tag>}
          {data === "editor" && <Tag>Редактор</Tag>}
        </div>
      ),
      sorter: true,
    },
    {
      dataIndex: "blocked",
      key: "blocked",
      align: "center",
      title: "Статус",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (data) =>
        data ? (
          <Badge status="error" text="Заблокирован" />
        ) : (
          <Badge status="success" text="Активен" />
        ),
    },
    {
      dataIndex: "last_login_at",
      key: "last_login_at",
      align: "center",
      title: "Посл. вход",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (data) =>
        data ? (
          <div>
            <span className="block text-sm font-normal text-neutral-400">
              {dayjs(data).fromNow()}
            </span>
            <span className="block text-sm font-medium text-black">
              {dayjs(data).format("DD.MM.YYYY HH:mm")}
            </span>
          </div>
        ) : (
          <span className="block text-sm font-normal text-neutral-400">
            Никогда
          </span>
        ),
      sorter: true,
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (_, all) => (
        <Link to={`/users/edit/${all.id}`}>
          <Button icon={<EditOutlined />}>Открыть</Button>
        </Link>
      ),
    },
  ];

  return (
    <div className="grid grid-cols-12 gap-4">
      {includes("search", showFilters) && (
        <div className="col-span-12 md:col-span-4">
          <Filters.Search
            defaultValue={filter?.name}
            onSearch={onFilterChange}
          />
        </div>
      )}
      {includes("email", showFilters) && (
        <div className="col-span-12 md:col-span-4">
          <Filters.Email
            defaultValue={filter?.email}
            onSearch={onFilterChange}
          />
        </div>
      )}
      {includes("role", showFilters) && (
        <div className="col-span-12 md:col-span-4">
          <Filters.Role defaultValue={filter?.role} onChange={onFilterChange} />
        </div>
      )}
      {includes("status", showFilters) && (
        <div className="col-span-12 md:col-span-6">
          <Filters.Status
            defaultValue={filter?.blocked}
            onChange={onFilterChange}
          />
        </div>
      )}
      {includes("email_confirmed", showFilters) && (
        <div className="col-span-12 md:col-span-6">
          <Filters.EmailConfirmed
            defaultValue={filter?.blocked}
            onChange={onFilterChange}
          />
        </div>
      )}
      <div className="col-span-12">
        <Table
          showHeader={showHeader}
          onChange={getTableData}
          pagination={showPagination && pagination}
          dataSource={tableData}
          loading={loading}
          className="rounded-sm overflow-hidden w-full"
          rowKey="id"
          scroll={{ x: "max-content" }}
          columns={columns}
        />
      </div>
    </div>
  );
}
