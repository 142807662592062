import axios from "./default";

const API = "/admin/settings";

export const getAll = (params) =>
  axios
    .get(`${API}/`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));

export const getOne = (params) =>
  axios
    .get(`${API}/type/${params.type}`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));

export const update = (data) =>
  axios
    .post(`${API}/type/${data.type}`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
