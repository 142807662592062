import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export default function FilterItem({ onChange, ...props }) {
  const onSearch = (value) => {
    if (value) {
      onChange("url", value);
      return;
    }

    onChange("url", null);
  };

  return (
    <Input.Search
      placeholder="Поиск по URL"
      allowClear
      onSearch={onSearch}
      enterButton="Найти"
      prefix={<SearchOutlined />}
      {...props}
    />
  );
}
