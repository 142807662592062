import { Dropdown, Card, Tooltip, Avatar, Divider, Alert } from "antd";
import {
  SaveOutlined,
  SendOutlined,
  ClockCircleOutlined,
  HighlightOutlined,
  StopFilled,
  CalendarOutlined,
  EditOutlined,
  UserOutlined,
  GlobalOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import Planned from "./planned";
import Publish from "./publish";
import * as API from "@api";
import { useEffect, useState } from "react";
import { useAuth } from "@contexts";

export default function Actions(params) {
  const { currentUser } = useAuth();
  const [scheduled, setScheduled] = useState(null);
  const [next, setNext] = useState(null);

  useEffect(() => {
    API.Projects.listScheduled().then((res) => {
      const planned =
        res?.data?.find((s) => s?.project?.id === params?.data?.id) || null;

      setScheduled(planned);
    });
  }, []);

  const handleActionsClick = ({ key }) => {
    switch (key) {
      case "publish":
      case "schedule":
        return setNext(key);

      case "unpublished":
        params.form.setFieldsValue({ next_status: key });
        return params.form.submit();

      case "cancel_publish":
        params.form.setFieldsValue({ next_status: key });
        return params.form.submit();

      case "open_url":
        const slug = params?.form?.getFieldValue("slug");
        return window.open(`https://kapital.kz/project/${slug}`, "_blank");

      default:
        break;
    }
  };

  return (
    <Card title="Публикация" bordered={false}>
      {scheduled && (
        <Alert
          type="warning"
          showIcon
          description={`Этот спецпроект запланирован к публикации в ${dayjs(
            scheduled?.nextDate
          ).format(
            "HH:mm DD.MM.YYYY"
          )}. Вы все еще можете внести изменения в спецпроект.`}
          message="Запланирован"
          className="mb-6"
        />
      )}
      {params?.data?.published && (
        <Alert
          type="success"
          showIcon
          description="Спецпроект опубликован на сайте и доступен к просмотру."
          message="Опубликован"
          className="mb-6"
        />
      )}

      <Dropdown.Button
        type="primary"
        className="m-0 full-width-dropdown"
        block
        menu={{
          items: [
            {
              label: "Опубликовать сейчас",
              key: "publish",
              icon: <SendOutlined />,
              className:
                !params?.data?.published &&
                !scheduled &&
                "bg-blue-100 hover:bg-blue-500 text-blue-900 hover:text-white",
              disabled: params?.data?.published || scheduled,
            },
            {
              type: "divider",
            },
            {
              label: "Запланировать публикацию",
              key: "schedule",
              disabled: scheduled || params?.data?.published,
              icon: <ClockCircleOutlined />,
            },
            {
              type: "divider",
            },
            {
              label: "Открыть на сайте",
              key: "open_url",
              icon: <GlobalOutlined />,
              disabled: !params?.data?.published,
            },
            {
              type: "divider",
            },
            {
              label: "Отменить авто-публикацию",
              key: "cancel_publish",
              danger: true,
              icon: <StopFilled />,
              hidden: !scheduled,
            },
            {
              label: "Снять с публикации",
              key: "unpublished",
              danger: true,
              icon: <StopFilled />,
              hidden: scheduled,
              disabled: !params?.data?.published,
            },
          ],
          onClick: handleActionsClick,
        }}
        onClick={params.form.submit}
      >
        <SaveOutlined /> Сохранить
      </Dropdown.Button>

      <Divider />

      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 md:col-span-1">
          <span className="text-sm flex flex-wrap justify-center items-center w-full text-neutral-600">
            <Tooltip title="Дата создания" placement="bottom">
              <CalendarOutlined className="text-primary mr-1" />{" "}
              {dayjs(params?.data?.created_at).format("HH:mm · DD.MM.YYYY")}
            </Tooltip>
          </span>
        </div>
        <div className="col-span-2 md:col-span-1">
          <span className="text-sm flex flex-wrap justify-center items-center w-full text-neutral-600">
            <Tooltip title="Дата последнего изменения" placement="bottom">
              <EditOutlined className="text-primary mr-1" />{" "}
              {dayjs(params?.data?.created_at).format("HH:mm · DD.MM.YYYY")}
            </Tooltip>
          </span>
        </div>
      </div>
      <Planned
        form={params.form}
        onCancel={() => setNext(null)}
        open={next === "schedule"}
      />
      <Publish
        form={params.form}
        onCancel={() => setNext(null)}
        open={next === "publish"}
      />
    </Card>
  );
}
