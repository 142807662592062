import axios from "axios";
import config from "@config";

const API = config.staticApi;

export const getImage = (file) => {
  return axios
    .get(`${API}/img`, { params: { file }, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
};

export const uploadImage = (data) => {
  const form = new FormData();
  form.append("file", data?.file);

  return axios
    .post(`${API}/img/upload`, form, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
};

export const uploadImages = (data) => {
  const form = new FormData();
  data.files.map((file) => form.append("files", file));

  return axios
    .post(`${API}/img/upload_multiple`, form, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
};

export const uploadTts = (data) => {
  return axios
    .post(`${API}/tts/convert`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
};
