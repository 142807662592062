import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Loading } from "@components";
import { message } from "antd";
import { Navigate } from "react-router-dom";
import * as API from "@api";

export default function ProductPage() {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!data) {
      API.Projects.create({
        title: `Черновик ${new Date().toLocaleString()}`,
      })
        .then((res) => {
          if (!res || res?.error) {
            throw new Error("Ошибка создания черновика");
          }
          setData(res?.data);
        })
        .catch((err) => {
          return message.error(err?.message || "Ошибка создания черновика", 2);
        });
    }
  }, [data]);

  if (data?.id) {
    return <Navigate to={`/projects/edit/${data?.id}`} />;
  }

  return <Loading />;
}
