import Name from "./name";
import Slug from "./slug";

import { Divider, Tag } from "antd";

export default function Details(props) {
  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="col-span-12">
        <Tag>ID {props?.form?.getFieldValue("id")}</Tag>
      </div>
      <div className="col-span-12">
        <Name {...props} />
      </div>
      <div className="col-span-12">
        <Slug {...props} />
      </div>
      <div className="col-span-12">
        <Divider className="my-2" />
      </div>
    </div>
  );
}
