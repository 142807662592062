import { useEffect, useState } from "react";
import { Table, message, Button, Badge, Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { includes } from "ramda";
import { Link } from "react-router-dom";
import { Imgproxy } from "@utils";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import * as API from "@api";
import * as Filters from "./filters";

dayjs.extend(relativeTime);

export default function DataTable({
  showHeader = true,
  showFilters = ["search", "dates", "status", "published", "type"],
  searchParams = null,
  setSearchParams = () => {},
  pageSize = 10,
  showPagination = true,
  compact = false,
}) {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(searchParams?.search || null);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState(searchParams?.filter || {});
  const [pagination, setPagination] = useState({
    current: searchParams?.pagination?.current
      ? parseInt(searchParams.pagination.current)
      : 1,
    pageSize,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
    showTotal: (total) => `Найдено: ${total}`,
  });

  useEffect(() => {
    getTableData();
  }, [filter, search]);

  async function getTableData(params = pagination, _, sorter) {
    setLoading(true);

    setSearchParams({
      filter,
      search,
      pagination: {
        current: params.current,
      },
    });

    const _res = await API.People.getAll({
      filter,
      q: search,
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
      order: sorter?.field && {
        [sorter?.field]: sorter?.order === "ascend" ? "ASC" : "DESC",
      },
    });

    if (!_res.data) {
      return message.error("Ошибка при запросе данных, попробуйте позже", 2);
    }

    setTableData(_res.data.rows);
    setPagination({
      ...params,
      total: _res.data.count,
      showTotal: (total) => `Найдено: ${total}`,
    });
    setLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter(
      { ...filter, [f]: v || undefined },
      setPagination({ ...pagination, current: 1 })
    );
  }

  function onSearch(v) {
    setSearch(v, setPagination({ ...pagination, current: 1 }));
  }

  const columns = [
    {
      dataIndex: "photo",
      key: "photo",
      align: "center",
      width: 80,
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (data) => (
        <img
          loading="lazy"
          src={
            data ? Imgproxy.getImage(data, { width: 160 }) : "/img/no-img.svg"
          }
          alt="Фото персоны"
          className="block mx-auto w-32 h-20 object-contain object-center rounded-sm bg-neutral-50 p-1"
        />
      ),
    },
    {
      dataIndex: "info",
      key: "info",
      align: "left",
      width: 300,
      render: (_, data) => (
        <Link to={`/people/edit/${data.id}`}>
          <span className="block text-base font-medium text-black leading-tight mb-1">
            {data?.full_name}
          </span>
          <span className="block text-sm font-normal text-neutral-500 leading-tight">
            {data?.current_position || "Позиция не указана"}
          </span>
        </Link>
      ),
    },
    {
      dataIndex: "type",
      key: "type",
      align: "center",
      title: "Тип",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (data) => (
        <div>
          {data === "expert" && (
            <Tag className="m-0" color="orange">
              Эксперт
            </Tag>
          )}
          {data === "dossier" && (
            <Tag className="m-0" color="blue">
              Досье
            </Tag>
          )}
          {data === "both" && <Tag className="m-0">Эксперт + Досье</Tag>}
        </div>
      ),
      sorter: true,
    },
    {
      dataIndex: "published",
      key: "published",
      align: "center",
      title: "Статус",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (isPublished) => (
        <Badge
          status={isPublished ? "success" : "default"}
          text={isPublished ? "Опубликован" : "Черновик"}
        />
      ),
      sorter: true,
    },
    {
      dataIndex: "published_at",
      key: "published_at",
      align: "center",
      title: "Дата публикации",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (data) =>
        data ? (
          <span className="block text-sm font-medium text-black">
            {dayjs(data).format("DD.MM.YYYY HH:mm")}
          </span>
        ) : (
          <span className="block text-sm text-neutral-500">Не указано</span>
        ),
      sorter: true,
    },
    {
      dataIndex: "updated_at",
      key: "updated_at",
      align: "center",
      title: "Посл. изменение",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (data) => (
        <div>
          <span className="block text-sm font-normal text-neutral-400">
            {dayjs(data).fromNow()}
          </span>
          <span className="block text-sm font-medium text-black">
            {dayjs(data).format("DD.MM.YYYY HH:mm")}
          </span>
        </div>
      ),
      sorter: true,
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (_, all) => (
        <Link to={`/people/edit/${all.id}`}>
          <Button icon={<EditOutlined />}>Открыть</Button>
        </Link>
      ),
    },
  ];

  return (
    <div className="grid grid-cols-12 gap-4">
      {includes("search", showFilters) && (
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <Filters.Search defaultValue={search} onSearch={onSearch} />
        </div>
      )}
      {includes("status", showFilters) && (
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <Filters.Status
            defaultValue={filter?.status}
            onChange={onFilterChange}
          />
        </div>
      )}
      {includes("dates", showFilters) && (
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <Filters.Dates
            defaultValue={filter?.created_at}
            onChange={onFilterChange}
          />
        </div>
      )}
      {includes("dates", showFilters) && (
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <Filters.Type defaultValue={filter?.type} onChange={onFilterChange} />
        </div>
      )}
      <div className="col-span-12">
        <Table
          showHeader={showHeader}
          onChange={getTableData}
          pagination={showPagination && pagination}
          dataSource={tableData}
          loading={loading}
          className="rounded-sm overflow-hidden w-full"
          rowKey="id"
          scroll={{ x: "max-content" }}
          columns={columns}
        />
      </div>
    </div>
  );
}
