import { Dropdown, Card, Tooltip, Avatar, Divider, Alert, Button } from "antd";
import {
  SaveOutlined,
  SendOutlined,
  ClockCircleOutlined,
  HighlightOutlined,
  StopFilled,
  CalendarOutlined,
  EditOutlined,
  UserOutlined,
  GlobalOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import Planned from "./planned";
import Publish from "./publish";
import { useState } from "react";
import { useAuth } from "@contexts";

export default function Actions(params) {
  const { currentUser } = useAuth();
  const [next, setNext] = useState(null);

  const handleActionsClick = ({ key }) => {
    switch (key) {
      case "publish":
      case "schedule":
        return setNext(key);

      case "unpublished":
      case "cancel_publish":
      case "in_review":
        params.form.setFieldsValue({ next_status: key });
        return params.form.submit();

      case "open_url":
        const id = params?.data?.id;
        const slug = params?.data?.slug;
        const category = params?.data?.category?.slug;
        return window.open(
          `https://kapital.kz/${category}/${id}/${slug}.html`,
          "_blank"
        );

      case "open_preview":
        const hashedId = params?.data?.meta?.hashed_preview_id || null;
        return window.open(`https://kapital.kz/preview/${hashedId}`, "_blank");

      default:
        break;
    }
  };

  return (
    <Card
      title="Публикация"
      bordered={false}
      extra={
        <Tooltip title="Последний, кто взаимодействовал с этим материалом.">
          <div className="flex justify-start items-center">
            <div className="text-right">
              <span className="block text-sm font-normal text-black leading-none">
                {params?.user?.name || currentUser?.name || "N/a"}
              </span>
              <span className="block text-xs font-normal text-neutral-400 leading-none">
                Посл. редактирование
              </span>
            </div>
            <Avatar
              className="bg-neutral-200 ml-2"
              size="small"
              icon={<UserOutlined className="text-neutral-400" />}
            />
          </div>
        </Tooltip>
      }
    >
      {params?.data?.status === "planned" && (
        <Alert
          type="warning"
          showIcon
          description={`Этот материал запланирован к публикации в 12:00 12.12.2021. Вы все еще можете внести изменения в материал.`}
          message="Запланирован"
          className="mb-6"
        />
      )}
      {params?.data?.status === "published" && (
        <Alert
          type="success"
          showIcon
          description="Материал опубликован на сайте и доступен к просмотру."
          message="Опубликован"
          className="mb-6"
        />
      )}
      {params?.data?.status === "unpublished" && (
        <Alert
          type="error"
          showIcon
          description="Материал снят с сайта и не доступен к просмотру."
          message="Снят с публикации"
          className="mb-6"
        />
      )}

      <Dropdown.Button
        type="primary"
        className="m-0 full-width-dropdown"
        block
        loading={params.loading}
        disabled={params.loading}
        menu={{
          items: [
            {
              label: "Опубликовать сейчас",
              key: "publish",
              icon: <SendOutlined />,
              className:
                params.data.status !== "published" &&
                params.data.status !== "planned" &&
                "bg-blue-100 hover:bg-blue-500 text-blue-900 hover:text-white",
              disabled:
                params.data.status === "published" ||
                params.data.status === "planned",
            },
            {
              type: "divider",
            },
            {
              label: "Запланировать публикацию",
              key: "schedule",
              disabled:
                params.data.status === "published" ||
                params.data.status === "planned",
              icon: <ClockCircleOutlined />,
            },
            {
              label: "Отправить на проверку",
              key: "in_review",
              icon: <HighlightOutlined />,
              disabled: params.data.status === "published",
            },
            {
              type: "divider",
            },
            {
              label: "Открыть на сайте",
              key: "open_url",
              icon: <GlobalOutlined />,
              disabled: params.data.status !== "published",
            },
            {
              label: "Открыть превью",
              key: "open_preview",
              icon: <EyeOutlined />,
              disabled: params.data.status !== "in_review",
            },
            {
              type: "divider",
            },
            {
              label: "Отменить публикацию",
              key: "cancel_publish",
              danger: true,
              icon: <StopFilled />,
              hidden: params.data.status !== "planned",
            },
            {
              label: "Снять с публикации",
              key: "unpublished",
              danger: true,
              icon: <StopFilled />,
              hidden: params.data.status === "planned",
              disabled: params.data.status !== "published",
            },
          ],
          onClick: handleActionsClick,
        }}
        onClick={params.form.submit}
      >
        <SaveOutlined /> Сохранить
      </Dropdown.Button>

      {params.data.status === "in_review" && (
        <Button
          onClick={() => handleActionsClick({ key: "open_preview" })}
          className="mt-3"
          block
          icon={<EyeOutlined />}
        >
          Открыть превью
        </Button>
      )}

      <Divider />

      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 md:col-span-1">
          <span className="text-sm flex flex-wrap justify-center items-center w-full text-neutral-600">
            <Tooltip title="Дата создания" placement="bottom">
              <CalendarOutlined className="text-primary mr-1" />{" "}
              {dayjs(params?.data?.created_at).format("HH:mm · DD.MM.YYYY")}
            </Tooltip>
          </span>
        </div>
        <div className="col-span-2 md:col-span-1">
          <span className="text-sm flex flex-wrap justify-center items-center w-full text-neutral-600">
            <Tooltip title="Дата последнего изменения" placement="bottom">
              <EditOutlined className="text-primary mr-1" />{" "}
              {dayjs(params?.data?.created_at).format("HH:mm · DD.MM.YYYY")}
            </Tooltip>
          </span>
        </div>
      </div>
      <Planned
        form={params.form}
        onCancel={() => setNext(null)}
        open={next === "schedule"}
      />
      <Publish
        form={params.form}
        onCancel={() => setNext(null)}
        open={next === "publish"}
      />
    </Card>
  );
}
