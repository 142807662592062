import striptags from "striptags";

export const sanitizeContent = ({ title, subtitle, blocks }) => {
  if (!title || !subtitle || !blocks) {
    return null;
  }

  let tts = `${title}. `;

  if (subtitle) {
    tts += `${subtitle}. `;
  }

  blocks.forEach((block) => {
    if (block?.type === "paragraph") {
      tts += striptags(block?.data?.text) + ". ";
    }

    if (block?.type === "heading") {
      tts += striptags(block?.data?.text) + ". ";
    }

    if (block?.type === "list") {
      tts += striptags(block?.data?.items?.join(", ")) + ". ";
    }

    if (block?.type === "sideimage") {
      tts += striptags(block?.data?.content) + ". ";
    }

    if (block?.type === "quote") {
      tts +=
        "Цитата: " +
        striptags(block?.data?.text) +
        ". -" +
        striptags(block?.data?.caption) +
        ". ";
    }

    if (block?.type === "table") {
      tts += "Таблица. ";
    }
  });

  return tts.replaceAll("..", ".").replaceAll(" .", ".");
};
