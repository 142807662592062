import { Select } from "antd";
import { useEffect, useState } from "react";
import * as API from "@api";

export default function ArticleFilter({ onChange, ...props }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    API.Categories.getAll({ limit: 1000 }).then((res) =>
      setData(
        res?.data?.rows
          ?.filter((row) => !row.parent_id)
          .map((val) => ({
            label: val?.name,
            value: val?.id?.toString(),
          })) || []
      )
    );
  }, []);

  return (
    <Select
      mode="multiple"
      className="w-full"
      placeholder="Рубрика"
      allowClear
      showSearch
      filterOption={(input, option) =>
        option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toLowerCase())
      }
      onChange={(v) => onChange("category_id", v)}
      options={data}
      {...props}
    />
  );
}
