import axios from "./default";

const API = "/admin/analytics";

export const getArticles = (params) =>
  axios
    .get(`${API}/articles`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));

export const getPeople = (params) =>
  axios
    .get(`${API}/people`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));

export const getProjects = (params) =>
  axios
    .get(`${API}/projects`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));
