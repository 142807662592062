import { HTMLEditor } from "@components";

export default function Content({ form }) {
  return (
    <div>
      <span className="block w-full font-normal">
        <i className="not-italic text-rose-500">*</i> Контент
      </span>
      <HTMLEditor
        className="w-full mt-2"
        value={form?.getFieldValue(["data", "content"])}
        onChange={(value) => {
          form?.setFieldsValue({
            data: {
              ...form?.getFieldValue("data"),
              content: value,
            },
          });
        }}
      />
    </div>
  );
}
