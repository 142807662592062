import { Form, Input } from "antd";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name="name"
      className="w-full"
      label="Название"
      rules={[
        {
          required: true,
          message: "Это поле обязательно к заполнениб",
        },
      ]}
    >
      <Input
        controls={false}
        minLength={3}
        className="w-full"
        placeholder="Введите название проекта"
      />
    </Form.Item>
  );
}
