import { Card, List, message } from "antd";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Imgproxy } from "@utils";
import dayjs from "dayjs";
import classNames from "classnames";
import * as API from "@api";

export default function InReview() {
  const [data, setData] = useState([]);

  useEffect(() => {
    API.Articles.getAll({ filter: { status: "in_review" }, limit: 10 })
      .then((res) => setData(res?.data?.rows))
      .catch((err) =>
        message.error(err.message || "Ошибка при запросе данных", 2)
      );
  }, []);

  return (
    <Card
      className="h-full"
      bordered={false}
      title="Материалы на рассмотрении"
      extra={
        <Link to="/articles?filter%5Bstatus%5D=in_review&pagination%5Bcurrent%5D=1">
          Открыть
        </Link>
      }
    >
      <List
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <img
                  loading="lazy"
                  src={
                    item?.image
                      ? Imgproxy.getImage(item?.image, {
                          width: 224,
                          height: 150,
                        })
                      : "/img/no-img.svg"
                  }
                  alt="Изображение материала"
                  className="block mx-auto w-28 object-contain rounded-sm bg-white"
                />
              }
              title={
                <div className="flex flex-col">
                  <span className="text-sm block text-primary font-normal mb-1">
                    {item?.category?.name}
                  </span>
                  <Link
                    to={`/articles/edit/${item?.id}`}
                    className="text-base font-semibold block leading-snug text-black"
                  >
                    {item?.title}
                  </Link>
                </div>
              }
              description={item?.subtitle}
            />
          </List.Item>
        )}
      />
    </Card>
  );
}
