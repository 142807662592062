import { Modal, DatePicker, Switch, message } from "antd";
import { useState } from "react";
import { SendOutlined, CheckCircleFilled } from "@ant-design/icons";
import dayjs from "dayjs";

export default function Planned({ form, open, onCancel }) {
  const [loading, setLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const onDateSelect = (date) => {
    if (date) {
      return form.setFieldsValue({
        planned_at: date.set("second", 0).format(),
      });
    }

    return form.setFieldsValue({ planned_at: null });
  };

  return (
    <Modal
      open={open}
      okText="Сохранить и отправить"
      okButtonProps={{
        loading,
        icon: <SendOutlined />,
        disabled: loading || !isReady,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
      onOk={() => {
        setLoading(true);

        const date = form.getFieldValue("planned_at");

        if (!date || dayjs(date).isBefore(dayjs())) {
          setLoading(false);
          return message.error("Дата публикации не указана или уже прошла");
        }

        form.setFieldsValue({ next_status: "planned" });
        form.submit();
        setLoading(false);
      }}
      centered
      width={700}
      title="Запланировать публикацию"
      destroyOnClose
      onCancel={() => {
        if (loading) return;
        onDateSelect(null);
        onCancel();
      }}
    >
      <div className="grid grid-cols-12 gap-4 my-5">
        <div className="col-span-12 md:col-span-6 bg-neutral-50 rounded-xl p-7 flex flex-col justify-between h-full">
          <div className="mb-20">
            <img
              src="/img/icon-schedule-clock.svg"
              width={40}
              height={40}
              className="block mx-auto"
              alt="Дата и время"
            />
            <span className="block w-full text-center font-semibold text-base text-black my-3">
              <i className="not-italic text-rose-600">*</i> Когда выпускаем?
            </span>
            <span className="block w-full text-center font-normal text-sm text-neutral-500">
              Выберите дату и время, когда спецпроект должен быть опубликован
            </span>
          </div>
          <DatePicker
            className="w-full"
            showTime
            format="DD.MM.YYYY HH:mm"
            placeholder="Выберите дату и время"
            onChange={onDateSelect}
            disabledDate={(current) =>
              current && current < dayjs().subtract(1, "day").endOf("day")
            }
          />
        </div>
        <div className="col-span-12 md:col-span-6 bg-neutral-50 rounded-xl p-7 flex flex-col justify-between h-full">
          <div className="mb-20">
            {/* <img
              src="/img/icon-schedule-telegram.svg"
              width={40}
              height={40}
              className="block mx-auto"
              alt="Соцсети"
            /> */}
            <CheckCircleFilled
              style={{ fontSize: 40 }}
              className="text-orange-500 block mx-auto"
            />
            <span className="block w-full text-center font-semibold text-base text-black my-3">
              Все готово к публикации?
            </span>
            <span className="block w-full text-center font-normal text-sm text-neutral-500">
              Убедитесь, что все данные заполнены и проверены. Шаблон
              бренд-страницы спецпроекта должен быть настроен на клиентской
              части (Front-end), а материалы (если они будут подкреплены) уже
              опубликованны или запланированы.
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="block text-sm text-black">Все готово</span>
            <Switch checked={isReady} onChange={setIsReady} />
          </div>
        </div>
      </div>
    </Modal>
  );
}
