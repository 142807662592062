import { Card, List, message } from "antd";
import { TagOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Imgproxy } from "@utils";
import dayjs from "dayjs";
import classNames from "classnames";
import * as API from "@api";

export default function ListModule({ form, ...props }) {
  return (
    <List
      dataSource={form.getFieldValue("related") || []}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            title={
              <div className="flex flex-wrap items-center gap-3">
                <TagOutlined className="text-primary" />
                <a
                  href={`/tags/edit/${item?.id}`}
                  className="text-base font-semibold block text-black m-0 leading-none"
                >
                  {item?.name}
                </a>
                <span className="text-xs font-normal text-neutral-400">
                  {item.slug}
                </span>
              </div>
            }
            description={item?.subtitle}
          />
        </List.Item>
      )}
      {...props}
    />
  );
}
