import { Form, Input } from "antd";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name={["data", "banner_url"]}
      className="w-full"
      help="Ссылка при нажатии на баннер."
      label="Ссылка на баннер сверху"
    >
      <Input
        controls={false}
        minLength={3}
        className="w-full"
        placeholder="Введите ссылку на баннер"
      />
    </Form.Item>
  );
}
