import { Form, Input } from "antd";

export default function FormInput(props) {
  const onChange = (e) => {
    const firstName = e.target.value;
    const lastName = props.form.getFieldValue("last_name") || null;
    const middleName = props.form.getFieldValue("middle_name") || null;

    const fullName = [lastName, firstName, middleName]
      .filter((item) => item)
      .join(" ");

    props.form.setFieldsValue({ full_name: fullName });
  };

  return (
    <Form.Item
      {...props}
      name="first_name"
      className="w-full"
      label="Имя"
      rules={[
        {
          required: true,
          message: "Это поле обязательно к заполнению",
        },
        {
          pattern: /^\S.*\S$/,
          message: "Нельзя использовать пробелы в начале и в конце строки",
        },
      ]}
    >
      <Input
        onChange={onChange}
        controls={false}
        className="w-full"
        placeholder="Введите имя"
      />
    </Form.Item>
  );
}
