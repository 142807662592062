import { Card, message, Statistic } from "antd";
import { Loading } from "@components";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import * as API from "@api";

export default function Rates() {
  const [data, setData] = useState(null);

  useEffect(() => {
    Promise.all([API.Rates.getNbk(), API.Rates.getOil(), API.Rates.getCrypto()])
      .then(([nbk, oil, crypto]) =>
        setData([...nbk?.data, ...oil?.data, ...crypto?.data])
      )
      .catch((err) => {
        message.error(err?.message, 2);
        setData(null);
      });
  }, []);

  const renderStats = () => {
    if (!data) return <Loading />;

    return data?.map((el, key) => (
      <div
        key={el.code + key}
        className="flex items-center justify-between mx-5"
      >
        <Statistic
          title={
            <div className="flex justify-between items-center">
              <span className="text-sm block leading-none">{el?.change}</span>
            </div>
          }
          value={el?.value}
          precision={2}
          valueStyle={{
            fontSize: 18,
            color:
              el?.index === "UP"
                ? "#3f8600"
                : el?.index === "DOWN"
                ? "#cf1322"
                : "#000",
          }}
          suffix={
            el?.index === "UP" ? (
              <ArrowUpOutlined />
            ) : el?.index === "DOWN" ? (
              <ArrowDownOutlined />
            ) : null
          }
          prefix={<span className="text-black">{el?.code}</span>}
        />
      </div>
    ));
  };

  return (
    <Card className="h-full" bordered={false}>
      <div>
        <Marquee
          gradient
          gradientColor={[255, 255, 255]}
          gradientWidth={20}
          pauseOnHover
          speed={60}
        >
          {renderStats()}
        </Marquee>
      </div>
    </Card>
  );
}
