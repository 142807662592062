import { Card, Button, Popconfirm } from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import Articles from "./articles";
import Authors from "./authors";
import Tags from "./tags";
import People from "./people";
import Details from "./details";

export default function EditEntry({ data, form, loading, onDelete }) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 flex flex-col gap-4 h-full">
        <Card title="Основная информация" bordered={false}>
          <Details data={data} form={form} />
          <div className="flex flex-col lg:flex-row gap-6 justify-between mt-5">
            <Button
              disabled={loading}
              loading={loading}
              htmlType="submit"
              type="primary"
              icon={<SaveOutlined />}
            >
              Сохранить
            </Button>
            <Popconfirm
              title="Вы уверены?"
              description="Данные удалятся безвозвратно."
              onConfirm={onDelete}
              okText="Да, удалить"
              okButtonProps={{ disabled: loading }}
            >
              <Button
                disabled={loading}
                type="text"
                danger
                icon={<DeleteOutlined />}
              >
                Удалить
              </Button>
            </Popconfirm>
          </div>
        </Card>
        <Card
          title="Связанные с этой SEO-записью объекты"
          bordered={false}
          className="h-full"
        >
          {data?.type === "article" && <Articles form={form} />}
          {data?.type === "author" && <Authors form={form} />}
          {data?.type === "tag" && <Tags form={form} />}
          {data?.type === "person" && <People form={form} />}
        </Card>
      </div>
    </div>
  );
}
