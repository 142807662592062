import { Form, Input } from "antd";

export default function FormInput(props) {
  return (
    <Form.Item {...props} name="url" className="w-full" label="URL">
      <Input
        disabled
        controls={false}
        minLength={3}
        className="w-full"
        placeholder="Введите URL без хоста (напр., /info/kazakhstan)"
      />
    </Form.Item>
  );
}
