import { Form, DatePicker } from "antd";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name="birthday"
      className="w-full"
      label="Дата рождения"
    >
      <DatePicker
        className="w-full"
        format="DD.MM.YYYY"
        placeholder="Выберите дату рождения"
      />
    </Form.Item>
  );
}
