import { useEffect, useState } from "react";
import { Table, message, Tag, Button, Collapse } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { includes } from "ramda";
import { Link } from "react-router-dom";
import { Dict } from "@utils";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import config from "@config";
import * as API from "@api";
import * as Filters from "./filters";

dayjs.extend(relativeTime);

export default function DataTable({
  showHeader = true,
  compact = false,
  showFilters = ["search", "type", "id", "url", "dates"],
  searchParams = null,
  setSearchParams = () => {},
  pageSize = 10,
  showPagination = true,
}) {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(searchParams?.search || null);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState(searchParams?.filter || {});
  const [pagination, setPagination] = useState({
    current: searchParams?.pagination?.current
      ? parseInt(searchParams.pagination.current)
      : 1,
    pageSize,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
    showTotal: (total) => `Найдено: ${total}`,
  });

  useEffect(() => {
    getTableData();
  }, [filter, search]);

  async function getTableData(params = pagination, _, sorter) {
    setLoading(true);

    setSearchParams({
      filter,
      search,
      pagination: {
        current: params.current,
      },
    });

    const _res = await API.Meta.getAll({
      q: search,
      filter,
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
      order: sorter?.field && {
        [sorter?.field]: sorter?.order === "ascend" ? "ASC" : "DESC",
      },
    });

    if (!_res.data) {
      return message.error(
        "Ошибка во время загрузки данных, попробуйте позже",
        2
      );
    }

    setTableData(_res.data.rows);
    setPagination({
      ...params,
      total: _res.data.count,
      showTotal: (total) => `Найдено: ${total}`,
    });
    setLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter(
      { ...filter, [f]: v || undefined },
      setPagination({ ...pagination, current: 1 })
    );
  }

  function onSearch(v) {
    setSearch(v, setPagination({ ...pagination, current: 1 }));
  }

  const columns = [
    {
      dataIndex: "info",
      key: "info",
      align: "left",
      title: "Объект",
      width: 400,
      render: (_, data) => (
        <Link to={`/meta/edit/${data.id}`}>
          <div className="mb-2">
            <span className="text-sm block text-neutral-500">
              SEO-заголовок
            </span>
            {data?.title ? (
              <span className="text-base block font-semibold text-black">
                {data.title}
              </span>
            ) : (
              <span className="text-sm block text-neutral-400">Нет</span>
            )}
          </div>
          <div className="mb-2">
            <span className="text-sm block text-neutral-500">SEO-описание</span>
            {data?.description ? (
              <span className="text-sm block font-normal text-black">
                {data?.description || "Нет"}
              </span>
            ) : (
              <span className="text-sm block text-neutral-400">Нет</span>
            )}
          </div>
        </Link>
      ),
    },
    {
      dataIndex: "type",
      key: "type",
      align: "center",
      title: "Тип SEO",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (data, all) => (
        <Tag color={Dict.META_OBJECT_TYPES[data]?.color}>
          {Dict.META_OBJECT_TYPES[data]?.label}
        </Tag>
      ),
      sorter: true,
    },
    {
      dataIndex: "url",
      key: "url",
      align: "center",
      title: "URL",
      width: 200,
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (data) => (
        <Link
          className="text-sm"
          to={`${config.frontend}${data}`}
          target="_blank"
        >
          {data}
        </Link>
      ),
      sorter: true,
    },
    {
      dataIndex: "updated_at",
      key: "updated_at",
      align: "center",
      title: "Посл. изменение",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (data) =>
        data ? (
          <span className="block text-sm font-medium text-black">
            {dayjs(data).format("DD.MM.YYYY HH:mm")}
          </span>
        ) : (
          <span className="block text-sm text-neutral-500">Нет</span>
        ),
      sorter: true,
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (_, all) => (
        <Link to={`/meta/edit/${all.id}`}>
          <Button icon={<EditOutlined />}>Открыть</Button>
        </Link>
      ),
    },
  ];

  return (
    <div className="grid grid-cols-12 gap-4">
      {includes("search", showFilters) && (
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <Filters.Search defaultValue={search} onSearch={onSearch} />
        </div>
      )}
      {includes("type", showFilters) && (
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <Filters.Type defaultValue={filter?.type} onChange={onFilterChange} />
        </div>
      )}
      {includes("url", showFilters) && (
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <Filters.URL defaultValue={filter?.url} onChange={onFilterChange} />
        </div>
      )}
      {includes("dates", showFilters) && (
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <Filters.Dates
            defaultValue={filter?.created_at}
            onChange={onFilterChange}
          />
        </div>
      )}
      <div className="col-span-12">
        <Table
          showHeader={showHeader}
          onChange={getTableData}
          pagination={showPagination && pagination}
          dataSource={tableData}
          loading={loading}
          className="rounded-sm overflow-hidden w-full"
          rowKey="id"
          scroll={{ x: "max-content" }}
          columns={columns}
        />
      </div>
    </div>
  );
}
