import { Card, Button, Popconfirm, Tag } from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAuth } from "@contexts";
import Details from "./details";
import Password from "./password";

export default function EditEntry({
  data,
  form,
  loading,
  onDelete,
  onPasswordUpdate,
}) {
  const { currentUser } = useAuth();
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-6 flex flex-col gap-4">
        <Card
          title="Основная информация"
          bordered={false}
          extra={[
            !data?.email_confirmed && (
              <Tag key="not-confirmed" className="m-0" color="orange">
                Почта не подтверждена
              </Tag>
            ),
            currentUser?.id === data?.id && (
              <Tag key="its-you" className="m-0 ml-2" color="blue">
                Это вы
              </Tag>
            ),
          ]}
        >
          <Details data={data} form={form} />
          <div className="flex flex-col lg:flex-row gap-6 justify-between mt-3">
            <Button
              disabled={loading}
              loading={loading}
              htmlType="submit"
              type="primary"
              icon={<SaveOutlined />}
            >
              Сохранить
            </Button>
            <Popconfirm
              title="Вы уверены?"
              description="Данные удалятся безвозвратно."
              onConfirm={onDelete}
              okText="Да, удалить"
              okButtonProps={{ disabled: loading }}
            >
              <Button
                disabled={loading}
                type="text"
                danger
                icon={<DeleteOutlined />}
              >
                Удалить
              </Button>
            </Popconfirm>
          </div>
        </Card>
      </div>
      <div className="col-span-12 md:col-span-6 flex flex-col gap-4">
        <Card title="Пароль" bordered={false}>
          <Password />
          <div className="flex flex-col lg:flex-row gap-6 justify-between mt-3">
            <Button
              onClick={onPasswordUpdate}
              disabled={loading}
              loading={loading}
              type="primary"
              icon={<SaveOutlined />}
            >
              Обновить пароль
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
}
