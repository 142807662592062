import { Form, Input } from "antd";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name={["meta", props?.locale?.code, "long_name"]}
      className="w-full"
      label={`Полное название (${props?.locale?.name})`}
      rules={[
        {
          required: true,
          message: "Это поле обязательно к заполнению",
        },
      ]}
    >
      <Input
        controls={false}
        minLength={3}
        className="w-full"
        placeholder="Введите перевод полного названия рубрики"
      />
    </Form.Item>
  );
}
