import { Form, Select } from "antd";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name="email_confirmed"
      className="w-full"
      label="Почта подтверждена"
      rules={[
        {
          required: true,
          message: "Пожалуйста, выберите значение",
        },
      ]}
    >
      <Select
        placeholder="Выберите значение"
        className="w-full"
        filterOption={(input, option) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={[
          {
            label: "Да",
            value: true,
          },
          {
            label: "Нет",
            value: false,
          },
        ]}
      />
    </Form.Item>
  );
}
