import Name from "./name";
import FirstName from "./first_name";
import LastName from "./last_name";
import MiddleName from "./middle_name";
import Slug from "./slug";
import Type from "./type";
import Birthday from "./birthday";
import Activities from "./activities";
import CurrentJob from "./current_job";
import CurrentPosition from "./current_position";
import Published from "./published";
import PublishedAt from "./published_at";
import Photo from "./photo";
import Biography from "./biography";
import BiographyShort from "./biography_short";

import { Divider, Tag } from "antd";

export default function Details(props) {
  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="col-span-12">
        <Tag>ID {props?.form?.getFieldValue("id")}</Tag>
      </div>
      <div className="col-span-12">
        <FirstName {...props} />
      </div>
      <div className="col-span-12">
        <LastName {...props} />
      </div>
      <div className="col-span-12">
        <MiddleName {...props} />
      </div>
      <div className="col-span-12">
        <Name {...props} />
      </div>
      <div className="col-span-12">
        <Slug {...props} />
      </div>
      <div className="col-span-12">
        <Type {...props} />
      </div>
      <div className="col-span-12">
        <Birthday {...props} />
      </div>
      <div className="col-span-12">
        <Divider className="my-2" orientation="left">
          Место работы
        </Divider>
      </div>
      <div className="col-span-12">
        <CurrentJob {...props} />
      </div>
      <div className="col-span-12">
        <CurrentPosition {...props} />
      </div>
      <div className="col-span-12">
        <Activities {...props} />
      </div>
      <div className="col-span-12">
        <Divider orientation="left" className="my-2">
          Статус на сайте
        </Divider>
      </div>
      <div className="col-span-12">
        <Published {...props} />
      </div>
      <div className="col-span-12">
        <PublishedAt {...props} />
      </div>
      <div className="col-span-12">
        <Divider className="my-2" />
      </div>
      <div className="col-span-12">
        <Photo {...props} />
      </div>
      <div className="col-span-12">
        <Divider orientation="left" className="my-2">
          Биография
        </Divider>
      </div>
      <div className="col-span-12">
        <Biography {...props} />
      </div>
      <div className="col-span-12">
        <BiographyShort {...props} />
      </div>
    </div>
  );
}
