import Title from "./title";
import Description from "./description";
import Tags from "./tags";
import Content from "./content";

export default function Details(props) {
  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="col-span-12">
        <Title {...props} />
      </div>
      <div className="col-span-12">
        <Description {...props} />
      </div>
      <div className="col-span-12">
        <Tags {...props} />
      </div>
      <div className="col-span-12">
        <Content {...props} />
      </div>
    </div>
  );
}
