import { Select } from "antd";

export default function FilterItem({ onChange, ...props }) {
  return (
    <Select
      className="w-full"
      placeholder="Роль"
      allowClear
      showSearch
      filterOption={(input, option) =>
        option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(v) => onChange("role", v)}
      options={[
        {
          value: "admin",
          label: "Администратор",
        },
        {
          value: "editor",
          label: "Редактор, журналист",
        },
        {
          value: "reader",
          label: "Читатель",
        },
      ]}
      {...props}
    />
  );
}
