import { Form, Input } from "antd";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name="slug"
      className="w-full"
      label="Slug (URL-адрес)"
    >
      <Input
        controls={false}
        minLength={3}
        className="w-full"
        placeholder="Введите URL-имя, например: halyk-bank"
      />
    </Form.Item>
  );
}
