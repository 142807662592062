import axios from "axios";
import qs from "qs";
import config from "@config";

const api = axios.create({
  baseURL: config.api,
});

// Function to refresh the access token using the refresh token
async function refreshAccessToken() {
  try {
    const response = await axios.post(config.api + "/auth/refresh", null, {
      withCredentials: true,
    });

    const { accessToken } = response.data;
    return accessToken;
  } catch (error) {
    console.log("refreshAccessToken error", error);
    // Handle error, e.g., redirect to login page
    throw error;
  }
}

// Axios request interceptor
api.interceptors.request.use(
  async (config) => {
    config.headers = {
      ...config.headers,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    };

    config.paramsSerializer = (params) => {
      return qs.stringify(params, {
        arrayFormat: "brackets",
        encode: false,
      });
    };

    // Get the access token from local storage or state
    const accessToken = localStorage.getItem("accessToken");

    // If the access token is available, add it to the request headers
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    config.withCredentials = true;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Axios response interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // If the error status is 401 Unauthorized and the original request doesn't have a retry flag
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Refresh the access token
      const newAccessToken = await refreshAccessToken();

      // Update the access token in local storage or state
      localStorage.setItem("accessToken", newAccessToken);

      // Set the new access token in the request headers
      api.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;

      // Retry the original request
      originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
      return api(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default api;
