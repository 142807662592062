import config from "@config";

export const getImage = (file, options) => {
  const url = `${config.staticApi}/img?file=${file}`;

  if (options?.width) {
    return url + `&width=${options.width}`;
  }

  if (options?.height) {
    return url + `&height=${options.height}`;
  }

  return url;
};
