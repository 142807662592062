import Title from "./title";
import Subtitle from "./subtitle";
import Category from "./category";
import Type from "./type";
import Locale from "./locale";
import Published from "./published";
import Authors from "./authors";
import People from "./people";
import Tags from "./tags";
import Project from "./project";

export default function Details(props) {
  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="col-span-12">
        <Title {...props} />
      </div>
      <div className="col-span-12">
        <Subtitle {...props} />
      </div>
      <div className="col-span-12 lg:col-span-3">
        <Locale {...props} />
      </div>
      <div className="col-span-12 lg:col-span-3">
        <Type {...props} />
      </div>
      <div className="col-span-12 lg:col-span-3">
        <Category {...props} />
      </div>
      <div className="col-span-12 lg:col-span-3">
        <Published {...props} />
      </div>
      <div className="col-span-12">
        <Project {...props} />
      </div>
      <div className="col-span-12 lg:col-span-6">
        <Authors {...props} />
      </div>
      <div className="col-span-12 lg:col-span-6">
        <People {...props} />
      </div>
      <div className="col-span-12">
        <Tags {...props} />
      </div>
    </div>
  );
}
