import { Form, DatePicker } from "antd";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name="published_at"
      className="w-full"
      label="Дата публикации"
    >
      <DatePicker
        className="w-full"
        showTime
        format="DD.MM.YYYY HH:mm"
        disabled
        placeholder="Дата публикации появится автоматически после публикации спецпроекта"
      />
    </Form.Item>
  );
}
