import { Checkbox } from "antd";

export default function ArticleFilter({ onChange, ...props }) {
  return (
    <Checkbox
      className="m-0"
      onChange={(e) => {
        onChange("is_editor_choice", e.target.checked ? 1 : 0);
      }}
      {...props}
    >
      Выбор редакции
    </Checkbox>
  );
}
