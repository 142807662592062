import { Form, Input } from "antd";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name="image_caption"
      className="w-full"
      label="Подпись к фото"
    >
      <Input
        controls={false}
        minLength={3}
        className="w-full"
        placeholder="Введите подпись под фото"
      />
    </Form.Item>
  );
}
