import { Select, Form } from "antd";
import { useEffect, useState } from "react";
import * as API from "@api";

export default function Project(params) {
  const [data, setData] = useState([]);

  useEffect(() => {
    API.Projects.getAll({ limit: 1000 }).then((res) =>
      setData(
        res?.data?.rows.map((val) => ({
          label: val?.name,
          value: val?.id,
        })) || []
      )
    );
  }, []);

  return (
    <Form.Item
      {...params}
      name="project_id"
      className="w-full"
      label="Спецпроект"
    >
      <Select
        className="w-full"
        placeholder="Спецпроект"
        allowClear
        showSearch
        filterOption={(input, option) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={data}
        {...params}
      />
    </Form.Item>
  );
}
