import { Card, Button, Popconfirm, message } from "antd";
import {
  AudioOutlined,
  CloudDownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import { sanitizeContent } from "@utils/tts";
import { Static } from "@api";
import config from "@config";

export default function TTS(params) {
  const [tts, setTts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (params?.form?.getFieldValue("tts")) {
      setTts(params?.form?.getFieldValue("tts"));
    }

    if (params?.form.getFieldValue("locale")?.code !== "ru") {
      setDisabled(true);
    }
  }, []);

  useEffect(() => {
    params.form.setFieldsValue({
      tts,
    });
  }, [tts]);

  const onRequestTts = async () => {
    const articleType = params.form.getFieldValue("type_id") || null;
    const locale = params.form.getFieldValue("locale") || null;

    if (!locale || articleType === 7) {
      return message.error(
        "Озвучка недоступна для Step-by-Step материалов.",
        2
      );
    }

    if (!locale || locale?.code !== "ru") {
      return message.error("Озвучка доступна только для русского языка.", 2);
    }

    const title = params.form.getFieldValue("title") || null;
    const subtitle = params.form.getFieldValue("subtitle") || null;
    const blocks =
      (await params.editorInstance.current
        .save()
        .then((res) => res.blocks)
        .catch(() => null)) || null;

    if (!title || !blocks || blocks?.length === 0) {
      return message.error(
        "Невозможно получить данные из редактора, проверьте правильность заполнения полей.",
        2
      );
    }

    const sanitized = sanitizeContent({ title, subtitle, blocks });

    setLoading(true);

    return await Static.uploadTts({
      article_id: params.data?.id,
      text: sanitized,
    })
      .then((res) => {
        if (!res?.data?.file) {
          return message.error(
            "Ошибка при запросе озвучки на стороне поставщика услуг.",
            2
          );
        }

        setTts(res.data.file);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Card title="Озвучка" bordered={false}>
      {!tts && (
        <Fragment>
          <AudioOutlined className="mx-auto block mb-3 text-3xl text-primary" />
          <span className="block w-full text-center text-sm font-normal text-neutral-400">
            Чтобы прикрепить озвучку Google Text-to-Speech, нажмите «Запросить
            озвучку».
          </span>
          <Popconfirm
            title="Материал готов?"
            okText="Да, продолжить"
            onConfirm={(e) => {
              e.preventDefault();
              onRequestTts();
            }}
            disabled={loading}
            popupVisible={!loading}
            description={
              <span className="block w-full max-w-xs">
                Убедитесь, что вы наполнили материал заголовком и текстом.
                Текущая версия материала будет направлена на озвучку.
              </span>
            }
          >
            <Button
              loading={loading}
              disabled={disabled || loading}
              icon={<CloudDownloadOutlined />}
              block
              className="mt-5"
            >
              {loading ? "Запрашиваем озвучку" : "Запросить озвучку"}
            </Button>
          </Popconfirm>
        </Fragment>
      )}

      {tts && (
        <Fragment>
          <audio className="w-full" controls autoPlay={false}>
            <source src={`${config.s3Url}/${tts}`} type="audio/mp3" />
          </audio>
          <Button
            block
            className="mt-3"
            type="text"
            danger
            icon={<DeleteOutlined />}
            onClick={() => setTts(null)}
          >
            Отвязать озвучку
          </Button>
        </Fragment>
      )}
    </Card>
  );
}
