import { Modal, DatePicker, Switch, message } from "antd";
import { useState } from "react";
import { SendOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

export default function Planned({ form, open, onCancel }) {
  const [loading, setLoading] = useState(false);
  const [slots, setSlots] = useState([]);

  const onSlotChange = (type, status) => {
    if (status) {
      return setSlots([...slots, { type, status: "pending" }]);
    }

    return setSlots(slots.filter((slot) => slot.type !== type));
  };

  return (
    <Modal
      open={open}
      okText="Отправить на публикацию"
      okButtonProps={{
        loading,
        icon: <SendOutlined />,
        disabled: loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
      onOk={() => {
        setLoading(true);
        form.setFieldsValue({ slot_params: slots, next_status: "published" });
        form.submit();
        setLoading(false);
      }}
      centered
      width={700}
      title="Публикация материала"
      destroyOnClose
      onCancel={() => {
        if (loading) return;
        onCancel();
      }}
    >
      <div className="grid grid-cols-12 gap-4 my-5">
        <div className="col-span-12 md:col-span-6 bg-neutral-50 rounded-xl p-7 flex flex-col justify-between h-full">
          <div className="mb-20">
            <img
              src="/img/icon-schedule-telegram.svg"
              width={40}
              height={40}
              className="block mx-auto"
              alt="Соцсети"
            />
            <span className="block w-full text-center font-semibold text-base text-black my-3">
              Отправляем в соц.сети?
            </span>
            <span className="block w-full text-center font-normal text-sm text-neutral-500">
              Материал будет отправлен в выбранные ниже соц.сети
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="block text-sm text-black">Telegram</span>
            <Switch onChange={(checked) => onSlotChange("telegram", checked)} />
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 bg-neutral-50 rounded-xl p-7 flex flex-col justify-between h-full">
          <div className="mb-20">
            <img
              src="/img/icon-schedule-push.svg"
              width={40}
              height={40}
              className="block mx-auto"
              alt="Push"
            />
            <span className="block w-full text-center font-semibold text-base text-black my-3">
              Рассылаем Push-уведомления?
            </span>
            <span className="block w-full text-center font-normal text-sm text-neutral-500">
              Пользователи, подписавшиеся на уведомления от портала получат
              Push-уведомление
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="block text-sm text-black">Web Push</span>
            <Switch
              onChange={(checked) => onSlotChange("one_signal_web", checked)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
