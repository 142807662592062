import { Form, Input } from "antd";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name="description"
      className="w-full"
      label="SEO-описание"
    >
      <Input.TextArea
        rows={3}
        controls={false}
        minLength={3}
        className="w-full"
        placeholder="Введите SEO-описание"
      />
    </Form.Item>
  );
}
