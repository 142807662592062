import { Card, Button, Popconfirm } from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Articles from "./articles";
import Related from "./related";
import Details from "./details";
import SEO from "./seo";

export default function EditEntry({ data, form, loading, onDelete }) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 flex flex-col gap-4 h-full">
        <Card title="Основная информация" bordered={false}>
          <Details data={data} form={form} />
          <SEO data={data} form={form} />
          <div className="flex flex-col lg:flex-row gap-6 justify-between mt-5">
            <Button
              disabled={loading}
              loading={loading}
              htmlType="submit"
              type="primary"
              icon={<SaveOutlined />}
            >
              Сохранить
            </Button>
            <Popconfirm
              title="Вы уверены?"
              description="Данные удалятся безвозвратно."
              onConfirm={onDelete}
              okText="Да, удалить"
              okButtonProps={{ disabled: loading }}
            >
              <Button
                disabled={loading}
                type="text"
                danger
                icon={<DeleteOutlined />}
              >
                Удалить
              </Button>
            </Popconfirm>
          </div>
        </Card>
        <Card
          title="Материалы по тегу"
          bordered={false}
          className="h-full"
          extra={
            <Link to="/articles">
              <Button type="link" className="m-0 p-0 h-fit w-fit">
                Все материалы
              </Button>
            </Link>
          }
        >
          <Articles form={form} />
        </Card>
        <Card
          title="Часто используется с тегами"
          bordered={false}
          className="h-full"
        >
          <Related form={form} />
        </Card>
      </div>
    </div>
  );
}
