import { Card, message } from "antd";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import * as API from "@api";

export default function Published() {
  const [data, setData] = useState(0);

  useEffect(() => {
    API.Analytics.getProjects({
      filter: {
        published: 1,
      },
    })
      .then((res) => setData(res?.data?.count || 0))
      .catch((err) =>
        message.error(err.message || "Ошибка при запросе данных", 2)
      );
  }, []);

  return (
    <Card
      className="h-full"
      bordered={false}
      title="Спецпроекты"
      extra={<Link to="/projects">Открыть</Link>}
    >
      <span
        className={classNames("block text-2xl font-normal leadning-none", {
          "text-green-500": data > 0,
          "text-black": data === 0,
        })}
      >
        {data}
      </span>
      <span className="block text-sm font-normal leading-none text-neutral-400 mt-2">
        На сайте
      </span>
    </Card>
  );
}
