import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@components";
import { message, Form } from "antd";
import DataForm from "./form";
import dayjs from "dayjs";
import * as API from "@api";

export default function EditData() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    API.Projects.getOne({ id }).then((res) => {
      if (res.error || !res) {
        return () =>
          message.error(
            "Ошибка во время получения данных, попробуйте позже",
            2
          );
      }

      if (res.data.published_at) {
        res.data.published_at = dayjs(res.data.published_at);
      }

      setData(res.data);
      setLoading(false);
    });
  }, []);

  if (!data) {
    return <Loading compact />;
  }

  const onSave = async (values) => {
    setLoading(true);
    const meta = form.getFieldValue("meta") || null;
    const image = form.getFieldValue("image") || null;
    const banner = form.getFieldValue("banner") || null;
    const logo = form.getFieldValue("logo") || null;
    const nextStatus = form.getFieldValue("next_status") || null;
    const planned_at = form.getFieldValue("planned_at") || null;

    if (!image || !banner || !logo) {
      return message.error("Необходимо добавить логотип и баннеры проекта", 2);
    }

    const normalizedValues = Object.entries(values).reduce(
      (acc, [key, value]) => {
        acc[key] = value !== undefined ? value : null;
        return acc;
      },
      {}
    );

    API.Projects.update({
      id,
      ...normalizedValues,
      image,
      banner,
      logo,
      meta,
    })
      .then(async (res) => {
        if (res?.error || !res?.data) {
          throw new Error(
            res?.message ||
              "Ошибка при сохранении спецпроекта, попробуйте позже"
          );
        }
        // await API.Revalidate.staticPages();
        // // Old article revalidate
        // await API.Revalidate.articles({ slug: data?.slug });
        // // Check if title changed
        // if (data?.slug !== res?.data?.slug) {
        //   await API.Revalidate.articles({ slug: res?.data?.slug });
        // }
        if (nextStatus === "planned") {
          const scheduled = await API.Projects.schedule({
            id,
            date: planned_at,
          });

          if (scheduled?.error || scheduled instanceof Error) {
            return message.error(
              scheduled?.message ||
                "Ошибка во время планирования спецпроекта, попробуйте позже",
              2
            );
          }

          return message.success("Спецпроект сохранен и запланирован", 2, () =>
            window.location.reload()
          );
        }

        if (nextStatus === "published") {
          const published = await API.Projects.publish({ id });

          if (published?.error || published instanceof Error) {
            return message.error(
              published?.message ||
                "Ошибка во время публикации спецпроекта, попробуйте позже",
              2
            );
          }

          return message.success(
            "Спецпроект успешно отправлен на публикацию",
            2,
            () => window.location.reload()
          );
        }

        if (nextStatus === "cancel_publish") {
          const cancelled = await API.Projects.cancelSchedule({ id });
          if (cancelled?.error || cancelled instanceof Error) {
            return message.error(
              cancelled?.message ||
                "Ошибка во время отмены публикации, попробуйте позже",
              2
            );
          }
          return message.success(
            "Спецпроект снят с запланированной публикации",
            2,
            () => window.location.reload()
          );
        }

        if (nextStatus === "unpublished") {
          const unpublished = await API.Projects.unpublish({ id });
          if (unpublished?.error || unpublished instanceof Error) {
            return message.error(
              unpublished?.message ||
                "Ошибка во время снятия с публикации, попробуйте позже",
              2
            );
          }
          return message.success("Спецпроект снят с публикации", 2, () =>
            window.location.reload()
          );
        }
        return message.success("Спецпроект сохранен", 1, () =>
          window.location.reload()
        );
      })
      .catch((err) =>
        message.error(
          err.message || "Ошибка при сохранении спецпроекта, попробуйте позже",
          2
        )
      )
      .finally(() => setLoading(false));
  };

  const onDelete = () => {
    setLoading(true);

    API.Articles.destroy({ id })
      .then(async (res) => {
        if (res?.error || !res?.data) {
          throw new Error(res?.message || "Error deleting article");
        }

        // await API.Revalidate.staticPages();

        // // Old article revalidate
        // await API.Revalidate.articles({ slug: data?.slug });

        return message.success(
          "Article deleted successfully",
          1,
          () => (window.location.href = "/articles")
        );
      })
      .catch((err) =>
        message.error(err.message || "Error deleting article", 2)
      );
  };

  return (
    <Form
      form={form}
      onFinish={onSave}
      onFinishFailed={(err) =>
        err?.errorFields?.map((msg) => message.error(msg.errors[0], 2))
      }
      layout="vertical"
      className="w-full form-container"
      initialValues={data}
    >
      <DataForm
        onDelete={onDelete}
        form={form}
        data={data}
        loading={loading}
        onSave={onSave}
      />
    </Form>
  );
}
