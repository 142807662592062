import { Card, Button, Divider } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Details from "./details";

export default function EditEntry({ data, form }) {
  return (
    <div className="grid grid-cols-12 xl:col-span-8 gap-4">
      <div className="col-span-12">
        <Card title="Основные настройки" bordered={false}>
          <Details data={data} form={form} />
          <Divider />
          <Button htmlType="submit" type="primary" icon={<SaveOutlined />}>
            Сохранить
          </Button>
        </Card>
      </div>
    </div>
  );
}
