import { Form, Input } from "antd";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name={["data", "tags"]}
      className="w-full"
      label="Мета-теги (SEO)"
      rules={[
        {
          required: true,
          message: "Мета-таеги страницы обязательны",
        },
      ]}
    >
      <Input.TextArea
        rows={3}
        controls={false}
        minLength={3}
        className="w-full"
        placeholder="Введите мета теги через запятую без пробелов"
      />
    </Form.Item>
  );
}
